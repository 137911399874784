import React, { ReactElement, useEffect, useState } from "react"
import Title from "modules/Carriers/components/Title/Title"
import { PageWrapper } from "modules/Carriers/components/Layout/Structure"
import { ParcelsContainer, Subtitle } from "modules/Carriers/Carriers.styled"
import * as parcelQueries from "services/graphql/queries/parcel.queries"
import { useAppApolloClient } from "services/graphql/apollo-client"
import { ParcelLine } from "modules/Carriers/components/ParcelLine/ParcelLine"
import { GetUnannouncedParcelsWithoutCoordinatesData } from "services/graphql/queries/parcel.queries"
import { UnannouncedParcelState } from "types/order.types"

export const Carriers = (): ReactElement => {
  const apolloClient = useAppApolloClient()
  const [data, setData] = useState<UnannouncedParcelState[]>([])

  const fetchUnannouncedParcels = () => {
    apolloClient
      .query<GetUnannouncedParcelsWithoutCoordinatesData>({
        query: parcelQueries.getUnannouncedParcelsWithoutCoordinates,
      })
      .then(data => {
        setData(
          data?.data?.getUnannouncedParcelsWithoutCoordinates.map(parcel => ({
            parcelNo: parcel.parcelNo,
            orderId: parcel.order.id,
            firstName: parcel?.order?.recipient?.firstName || "",
            lastName: parcel?.order?.recipient?.lastName || "",
            email: parcel?.order?.recipient?.email || "",
            phone: parcel?.order?.recipient?.phone || "",
            createdAt: parcel.createdAt,
            id: parcel.id,
          })) || [],
        )
      })
  }

  useEffect(() => {
    fetchUnannouncedParcels()
  }, [])

  return (
    <PageWrapper>
      <Title>Colis sans coordonnées</Title>
      <Subtitle>{data.length} colis ont besoin d’une mise à jour des coordonnées destinataire</Subtitle>
      <ParcelsContainer>
        {data.map(parcel => (
          <ParcelLine key={parcel.parcelNo} parcel={parcel} fetchUnannouncedParcels={fetchUnannouncedParcels} />
        ))}
      </ParcelsContainer>
    </PageWrapper>
  )
}
