import styled from "styled-components"
import { ToastContainer } from "react-toastify"

export const Container = styled.div`
  height: 100%;
`

export const StyledToastContainer = styled(ToastContainer)`
  top: 6.5rem;
`
