import { AnswerType } from "types/rating.types"

export const initialSurveyRatingState = {
  q6: { order: 6, type: AnswerType.OUT_OF_10, note: undefined },
  q7: { order: 7, type: AnswerType.OUT_OF_10, note: undefined },
  q8: { order: 8, type: AnswerType.OUT_OF_10, note: undefined },
  q9: { order: 9, type: AnswerType.OUT_OF_10, note: undefined },
  q10: { order: 10, type: AnswerType.OUT_OF_10, note: undefined },
  q11: { order: 11, type: AnswerType.CHOICE, note: undefined },
  q12: { order: 12, type: AnswerType.CHOICE, note: undefined },
  q13: { order: 13, type: AnswerType.CHOICE, note: undefined },
  q14: { order: 14, type: AnswerType.CHOICE, note: undefined },
  q15: { order: 15, type: AnswerType.CHOICE, note: undefined },
  q16: { order: 16, type: AnswerType.CHOICE, note: undefined },
  q17: { order: 17, type: AnswerType.AGREE_DISAGREE, note: undefined },
  q18: { order: 18, type: AnswerType.CHOICE, note: undefined },
}
