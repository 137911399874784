export const colissimoFirstIntentZipcodes = [
  "27100",
  "27340",
  "27360",
  "27460",
  "27590",
  "27610",
  "27690",
  "27740",
  "59100",
  "59115",
  "59117",
  "59126",
  "59150",
  "59152",
  "59166",
  "59170",
  "59200",
  "59223",
  "59236",
  "59250",
  "59290",
  "59390",
  "59420",
  "59510",
  "59560",
  "59700",
  "59780",
  "59890",
  "59910",
  "59960",
  "69005",
  "69007",
  "69008",
  "69009",
  "69110",
  "69150",
  "69190",
  "69200",
  "69320",
  "69350",
  "69360",
  "69500",
  "69600",
  "69680",
  "69720",
  "69740",
  "69780",
  "69800",
  "69960",
  "69970",
  "76100",
  "76120",
  "76140",
  "76300",
  "76320",
  "76350",
  "76410",
  "76500",
  "76530",
  "76650",
  "76800",
  "76000",
  "76116",
  "76130",
  "76160",
  "76230",
  "76240",
  "76420",
  "76520",
  "76680",
  "76750",
  "76770",
  "76850",
  "76920",
  "76113",
  "76150",
  "76250",
  "76360",
  "76380",
  "76480",
  "76690",
  "76710",
  "76840",
  "76960",
  "92000",
  "92100",
  "92130",
  "92150",
  "92200",
  "92210",
  "92250",
  "92300",
  "92400",
  "92410",
  "92430",
  "92500",
  "92800",
]

export const showBanner = (zipCode: string): boolean => {
  return zipCode ? colissimoFirstIntentZipcodes.includes(zipCode) : false
}
