import { Order } from "models/order.model"
import { ButtonWithIcon } from "modules/shared/components/Button/Button"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { corail, grey, white } from "utils/color"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import "modules/rating/screens/styles.scss"
import { AnimateProperties, AnswerInput, RatingState } from "types/rating.types"
import { isUndefined } from "utils/isUndefined"
import StandardForm from "modules/rating/components/Question/StandardForm/StandardForm"
import ColissimoForm from "modules/rating/components/Question/ColissimoForm/ColissimoForm"
import { ActionContainer, CtaBlocBottom, CtaContainer, StepContainer } from "modules/rating/screens/Rating.styled"
import { CarriersName } from "types/carriers.types"
import { backgroundGrey } from "theme/colors"
import {
  initialQuestionColissimoRatingState,
  initialQuestionStandardRatingState,
} from "modules/rating/datas/Question/QuestionDatas"

interface QuestionProps {
  order: Order
  onQuestionUpdated: (answers: AnswerInput[], feedback: string) => void
  rate: number
}

export const Question = ({ order, onQuestionUpdated, rate }: QuestionProps): ReactElement => {
  const [feedback, setFeedback] = useState<string>("")
  const [ratingState, setRatingState] = useState<RatingState>(
    order.deliveryCarrierName === CarriersName.COLISSIMO
      ? initialQuestionColissimoRatingState()
      : initialQuestionStandardRatingState(),
  )

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  /** Variable that stores answered questions only */
  const filteredAnswersRef = useRef<AnswerInput[]>([])

  /** Indirect reference to the feedback variable */
  const feedbackRef = useRef<string>("")

  function getButtonBackgroundColor(selected: boolean): string {
    return selected ? corail : backgroundGrey
  }

  function handleRatingUpdate(question: AnswerInput, item: string | number) {
    setRatingState(prevState => ({
      ...prevState,
      [`q${question.order}`]: { ...question, note: item },
    }))
  }

  function getAnimateProperties(question: AnswerInput, item: number): AnimateProperties {
    return {
      background: getButtonBackgroundColor(question.note === item),
      color: question.note === item ? white : grey,
    }
  }

  const isDisabled =
    order.deliveryCarrierName === CarriersName.COLISSIMO
      ? isUndefined(ratingState.q1.note) ||
        isUndefined(ratingState.q2.note) ||
        isUndefined(ratingState.q3.note) ||
        isUndefined(ratingState.q4.note) ||
        isUndefined(ratingState.q5.note)
      : isUndefined(ratingState.q1.note) || isUndefined(ratingState.q2.note) || isUndefined(ratingState.q3.note)

  filteredAnswersRef.current = Object.values(ratingState).filter(question => !isUndefined(question.note))
  feedbackRef.current = feedback

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current && filteredAnswersRef.current.length) {
        onQuestionUpdated(filteredAnswersRef.current, feedbackRef.current)
      }
    }
  }, [])

  return (
    <PeerChallengeFeedbackContainer>
      <StepContainer>
        <ActionContainer wider={order.deliveryCarrierName === CarriersName.COLISSIMO}>
          {order.deliveryCarrierName === CarriersName.COLISSIMO && (
            <ColissimoForm
              ratingState={ratingState}
              handleRatingUpdate={handleRatingUpdate}
              getAnimateProperties={getAnimateProperties}
              rate={rate}
            />
          )}
          {order.deliveryCarrierName !== CarriersName.COLISSIMO && (
            <StandardForm
              ratingState={ratingState}
              handleRatingUpdate={handleRatingUpdate}
              getAnimateProperties={getAnimateProperties}
              feedback={feedback}
              setFeedback={setFeedback}
            />
          )}
          <CtaBlocBottom>
            <CtaContainer>
              <ButtonWithIcon
                text="Suivant"
                disabled={isDisabled}
                icon={null}
                onClick={() => {
                  onQuestionUpdated(filteredAnswersRef.current, feedback)
                  isDataSent.current = true
                }}
              />
            </CtaContainer>
          </CtaBlocBottom>
        </ActionContainer>
      </StepContainer>
    </PeerChallengeFeedbackContainer>
  )
}
