import React, { ReactElement } from "react"
import { bottomToTopItem, topToBottom } from "modules/shared/animations/variants"
import { Input } from "modules/shared/components/Input/Input"
import { FormProps } from "types/rating.types"
import { colissimoFormData } from "modules/shared/datas/RatingDatas"
import {
  ActionContainerQuestionSpacing,
  Feedback,
  FeedbackEditor,
  FeedbackInformation,
  Message,
  MessageMotion,
  MessageSpacing,
  PeerRating,
  QuestionBlock,
  QuestionContainerV2,
  QuestionItemText,
  QuestionValues,
} from "modules/rating/screens/Rating.styled"

const ColissimoForm = ({ ratingState, handleRatingUpdate, getAnimateProperties, rate }: FormProps): ReactElement => {
  return (
    <ActionContainerQuestionSpacing>
      <PeerRating>
        <QuestionBlock>
          <MessageMotion variants={topToBottom}>
            <FeedbackInformation>
              <Message>
                {colissimoFormData.q1.title} <b>{rate}</b> ?
              </Message>
            </FeedbackInformation>

            <MessageSpacing>
              <Feedback>
                <FeedbackEditor>
                  <Input
                    placeholder={""}
                    behavior="textarea"
                    onChange={text => handleRatingUpdate(ratingState.q1, text)}
                    value={ratingState.q1.note as string}
                  />
                </FeedbackEditor>
              </Feedback>
            </MessageSpacing>
          </MessageMotion>

          <QuestionContainerV2>
            <Message>{colissimoFormData.q2.title}</Message>
            <QuestionValues variants={bottomToTopItem}>
              {colissimoFormData.q2.labels.map(item => (
                <QuestionItemText
                  onClick={() => handleRatingUpdate(ratingState.q2, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q2, item.value)}
                >
                  {item.label}
                </QuestionItemText>
              ))}
            </QuestionValues>
          </QuestionContainerV2>

          <QuestionContainerV2>
            <Message>{colissimoFormData.q3.title}</Message>
            <QuestionValues variants={bottomToTopItem}>
              {colissimoFormData.q3.labels.map(item => (
                <QuestionItemText
                  onClick={() => handleRatingUpdate(ratingState.q3, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q3, item.value)}
                >
                  {item.label}
                </QuestionItemText>
              ))}
            </QuestionValues>
          </QuestionContainerV2>

          <QuestionContainerV2>
            <Message>{colissimoFormData.q4.title}</Message>
            <QuestionValues variants={bottomToTopItem}>
              {colissimoFormData.q4.labels.map(item => (
                <QuestionItemText
                  onClick={() => handleRatingUpdate(ratingState.q4, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q4, item.value)}
                >
                  {item.label}
                </QuestionItemText>
              ))}
            </QuestionValues>
          </QuestionContainerV2>

          <QuestionContainerV2>
            <Message>{colissimoFormData.q5.title}</Message>
            <QuestionValues variants={bottomToTopItem}>
              {colissimoFormData.q5.labels.map(item => (
                <QuestionItemText
                  onClick={() => handleRatingUpdate(ratingState.q5, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q5, item.value)}
                >
                  {item.label}
                </QuestionItemText>
              ))}
            </QuestionValues>
          </QuestionContainerV2>
        </QuestionBlock>
      </PeerRating>
    </ActionContainerQuestionSpacing>
  )
}

export default ColissimoForm
