import React, { ReactElement } from "react"
import { bottomToTopItem, topToBottom } from "modules/shared/animations/variants"
import { FormProps } from "types/rating.types"
import { standardFormData } from "modules/shared/datas/RatingDatas"
import {
  Feedback,
  FeedbackEditor,
  FeedbackInformation,
  Message,
  MessageMotion,
  PeerRating,
  QuestionContainer,
  QuestionTitle,
  RatingContainer,
  RatingItemText,
  RatingValues,
} from "modules/rating/screens/Rating.styled"
import { Input } from "modules/shared/components/Input/Input"

interface StandardFormProps extends FormProps {
  feedback: string
  setFeedback: (feedback: string) => void
}

const StandardForm = ({
  ratingState,
  handleRatingUpdate,
  getAnimateProperties,
  feedback,
  setFeedback,
}: StandardFormProps): ReactElement => {
  return (
    <>
      <PeerRating>
        <MessageMotion variants={topToBottom}>
          <p>Dites nous en plus 😉</p>
        </MessageMotion>

        <RatingContainer>
          <QuestionContainer>
            <QuestionTitle>{standardFormData.q1.title}</QuestionTitle>
            <RatingValues variants={bottomToTopItem}>
              {standardFormData.q1.labels.map(item => (
                <RatingItemText
                  onClick={() => handleRatingUpdate(ratingState.q1, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q1, item.value)}
                >
                  {item.label}
                </RatingItemText>
              ))}
            </RatingValues>
          </QuestionContainer>

          <QuestionContainer>
            <QuestionTitle>{standardFormData.q2.title}</QuestionTitle>
            <RatingValues variants={bottomToTopItem}>
              {standardFormData.q2.labels.map(item => (
                <RatingItemText
                  onClick={() => handleRatingUpdate(ratingState.q2, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q2, item.value)}
                >
                  {item.label}
                </RatingItemText>
              ))}
            </RatingValues>
          </QuestionContainer>

          <QuestionContainer>
            <QuestionTitle>{standardFormData.q3.title}</QuestionTitle>
            <RatingValues variants={bottomToTopItem}>
              {standardFormData.q3.labels.map(item => (
                <RatingItemText
                  onClick={() => handleRatingUpdate(ratingState.q3, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q3, item.value)}
                >
                  {item.label}
                </RatingItemText>
              ))}
            </RatingValues>
          </QuestionContainer>

          <QuestionContainer>
            <MessageMotion variants={topToBottom}>
              <FeedbackInformation>
                <Message>Avez vous des suggestions d’améliorations à nous partager ?</Message>
              </FeedbackInformation>

              <Feedback>
                <FeedbackEditor>
                  <Input placeholder={""} behavior="textarea" onChange={text => setFeedback(text)} value={feedback} />
                </FeedbackEditor>
              </Feedback>
            </MessageMotion>
          </QuestionContainer>
        </RatingContainer>
      </PeerRating>
    </>
  )
}

export default StandardForm
