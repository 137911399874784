import React, { ReactElement, useEffect, useState } from "react"

import GLSLogo from "assets/logos/GLS.png"
import DHLLogo from "assets/logos/DHL.png"
import GeodisLogo from "assets/logos/Geodis.png"
import ColissionLogo from "assets/logos/Colissimo.png"
import PickmePicto from "assets/logos/Pickme-picto.png"
import {
  CarrierLogo,
  KeeperAvatar,
  KeeperAvatarContainer,
  KeeperDescriptionContainer,
  KeeperDetailsContainer,
  KeeperName,
  KeeperNoAvatar,
  KeeperNoAvatarContainer,
  KeeperSubtitle,
  OrderDescription,
  OrderDetailsContainer,
  OrderInfoContainer,
  OrderInfosCard,
  KeeperPictureContainer,
} from "modules/DestiChat/OrderInfos.styled"
import { CarriersName } from "types/carriers.types"
import { Keeper } from "types/keeper.types"
import { OrderChat } from "types/chat.types"

const KeeperDetails = ({ keeper }: { keeper: Keeper }): ReactElement => {
  return (
    <KeeperDetailsContainer>
      <KeeperPictureContainer>
        {keeper.pictureUrl ? (
          <KeeperAvatarContainer>
            <KeeperAvatar src={keeper.pictureUrl} />
          </KeeperAvatarContainer>
        ) : (
          <KeeperNoAvatarContainer>
            <KeeperNoAvatar src={PickmePicto} />
          </KeeperNoAvatarContainer>
        )}
      </KeeperPictureContainer>

      <KeeperDescriptionContainer>
        <KeeperName>{keeper.firstName}</KeeperName>
        <KeeperSubtitle>Votre Voisin-Relais</KeeperSubtitle>
      </KeeperDescriptionContainer>
    </KeeperDetailsContainer>
  )
}

const ParcelDetails = ({ order }: { order: OrderChat }): ReactElement => {
  // Should be refactor after test for handle responsive with media-queries rather than window.width
  // eslint-disable-next-line no-undef
  const windowWith = window.innerWidth
  const [isMobile, setIsMobile] = useState(windowWith < 620)

  useEffect(() => {
    function handleResize() {
      setIsMobile(windowWith < 620)
    }

    // eslint-disable-next-line no-undef
    window.addEventListener("resize", handleResize)

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <OrderDetailsContainer>
      <div style={{ display: "flex" }}>
        {{
          [CarriersName.COLISSIMO]: <CarrierLogo src={ColissionLogo} />,
          [CarriersName.DHL]: <CarrierLogo src={DHLLogo} />,
          [CarriersName.GEODIS]: <CarrierLogo src={GeodisLogo} />,
          [CarriersName.GLS]: <CarrierLogo src={GLSLogo} />,
        }[order.carrierName] || null}
      </div>
      <OrderDescription>
        {isMobile ? (
          <>
            <span>
              <b>{order.parcelNo}</b>
              {order.shipperName && (
                <>
                  {" de "} <b>{order.shipperName}</b>
                </>
              )}
            </span>
          </>
        ) : (
          <>
            <span>
              Numéro de suivi : <b>{order.parcelNo}</b>
            </span>
            {order.shipperName && (
              <p>
                De: <b>{order.shipperName}</b>
              </p>
            )}
          </>
        )}
      </OrderDescription>
    </OrderDetailsContainer>
  )
}

export const OrderInfos = ({ order }: { order: OrderChat }): ReactElement => {
  const keeper = {
    pictureUrl: order.keeperPictureUrl,
    firstName: order.keeperFirstName,
  }

  return (
    <OrderInfoContainer>
      <OrderInfosCard>
        <KeeperDetails keeper={keeper} />
        <ParcelDetails order={order} />
      </OrderInfosCard>
    </OrderInfoContainer>
  )
}
