import React, { ReactElement } from "react"
import {
  LineElementInfo,
  LineElementLabel,
  LineElementContent,
  LineCol,
} from "modules/Carriers/components/Table/LineElementCol/LineElementCol.styled"

interface LineElementColProps {
  size: number
  label?: string
  content: string | number | ReactElement
  width?: string // Width in %
  maxWidth?: string
}

export const LineElementCol = ({ size, label, content, width, maxWidth }: LineElementColProps): ReactElement => {
  return (
    <LineCol width={width} maxWidth={maxWidth} xs={width ? "unset" : size || 2}>
      <LineElementInfo>
        {!!label && <LineElementLabel>{label}</LineElementLabel>}
        <LineElementContent>{content}</LineElementContent>
      </LineElementInfo>
    </LineCol>
  )
}
