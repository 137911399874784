import React, { ReactElement } from "react"
import { Loader } from "semantic-ui-react"
import { LoadingContainer } from "modules/shared/components/LoadingComponent/LoadingComponent.styled"

interface LoadingComponentProps {
  paddingTop?: string
  paddingBottom?: string
}

export const LoadingComponent = ({
  paddingTop = "2em",
  paddingBottom = "2em",
}: LoadingComponentProps): ReactElement => {
  return (
    <LoadingContainer paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <Loader active inline />
    </LoadingContainer>
  )
}
