import React, { ReactElement } from "react"

import { HeaderContainer, PickmeLinkContainer, PickmeLogoContainer, ZendeskLinkContainer } from "./DestiChat.styled"
import PickmeLogo from "assets/logos/pickme-logo.png"
import { ParcelEventCode, ParcelEventReason, SaveParcelEventInput } from "types/parcel.event.types"
import { pickmeWebSite, zendeskLink } from "utils/links"

interface HeaderProps {
  handleSaveParcelEvent: (payload: SaveParcelEventInput) => void
  parcelId: string
}

export const Header = ({ handleSaveParcelEvent, parcelId }: HeaderProps): ReactElement => (
  <HeaderContainer>
    <PickmeLinkContainer href={pickmeWebSite}>
      <PickmeLogoContainer alt="logo pickme" src={PickmeLogo} />
    </PickmeLinkContainer>
    <ZendeskLinkContainer
      href={zendeskLink}
      onClick={() =>
        handleSaveParcelEvent({
          parcelId: parcelId,
          title: "Clic sur un CTA de la webPlateform.",
          description: "Le recipient Clic sur un CTA de LA webPlateform.",
          parcelEventCode: ParcelEventCode.RECIPIENT_CLICK_SUPPORT_LINK,
          parcelEventReason: ParcelEventReason.CLICK_CTA,
        })
      }
    >
      Service Client
    </ZendeskLinkContainer>
  </HeaderContainer>
)
