import React, { ReactElement, useEffect, useRef, useState } from "react"
import { corail, grey, white } from "utils/color"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import { bottomToTopItem, topToBottom } from "modules/shared/animations/variants"
import { ButtonWithIcon } from "modules/shared/components/Button/Button"
import { FiArrowRight } from "react-icons/fi"
import { AnimateProperties } from "types/rating.types"
import { isUndefined } from "utils/isUndefined"
import { OUT_OF_10 } from "modules/shared/datas/RatingDatas"
import {
  ActionContainer,
  CtaBlocBottom,
  CtaContainer,
  MessageGray,
  MessageMotion,
  PeerRating,
  RatingContainer,
  RatingItem,
  RatingValues,
  StepContainer,
  Subquestion,
} from "modules/rating/screens/Rating.styled"
import { backgroundGrey } from "theme/colors"
import { Order } from "models/order.model"
import RateBanner from "modules/shared/components/Banner/Banner"
import { CarriersName } from "types/carriers.types"
import { showBanner } from "utils/showBanner"

interface NpsProps {
  onNPSUpdated: (nps: number) => void
  order?: Order
}

export const NPS = ({ onNPSUpdated, order }: NpsProps): ReactElement => {
  const [npsState, setNpsState] = useState<number>(undefined)

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  /** Indirect reference to the npsState variable */
  const npsStateRef = useRef<number>(undefined)

  const isBanner =
    order.deliveryCarrierName !== CarriersName.COLISSIMO && showBanner(order?.address?.keeper?.address?.zipcode)

  function getButtonBackgroundColor(selected: boolean): string {
    return selected ? corail : backgroundGrey
  }

  function getAnimateProperties(item: number): AnimateProperties {
    return {
      background: getButtonBackgroundColor(item === npsState),
      color: item === npsState ? white : grey,
    }
  }

  const isDisabled = isUndefined(npsState)

  npsStateRef.current = npsState

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current && !isUndefined(npsStateRef.current)) {
        onNPSUpdated(npsStateRef.current)
      }
    }
  }, [])

  return (
    <PeerChallengeFeedbackContainer>
      <StepContainer>
        {isBanner && <RateBanner />}
        <ActionContainer marginTop={isBanner ? 18 : 90}>
          <>
            <div>
              <PeerRating>
                <MessageMotion variants={topToBottom}>
                  <Subquestion>Recommanderiez vous Pickme à votre entourage ?</Subquestion>
                  <MessageGray>0 signifiant “Pas du tout probable”, 10 signifiant “Très probable”</MessageGray>
                </MessageMotion>
                <RatingContainer>
                  <RatingValues variants={bottomToTopItem}>
                    {OUT_OF_10.map(item => (
                      <RatingItem
                        onClick={() => setNpsState(item)}
                        whileTap={{
                          scale: 0.9,
                        }}
                        key={item}
                        animate={getAnimateProperties(item)}
                      >
                        {item}
                      </RatingItem>
                    ))}
                  </RatingValues>
                </RatingContainer>
              </PeerRating>
            </div>
          </>
          <CtaBlocBottom>
            <CtaContainer>
              <ButtonWithIcon
                text="Suivant"
                disabled={isDisabled}
                icon={<FiArrowRight />}
                onClick={() => {
                  onNPSUpdated(npsStateRef.current)
                  isDataSent.current = true
                }}
              />
            </CtaContainer>
          </CtaBlocBottom>
        </ActionContainer>
      </StepContainer>
    </PeerChallengeFeedbackContainer>
  )
}
