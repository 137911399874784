import { gql } from "apollo-boost"
import { AnswerInput, RatingVersions } from "types/rating.types"
import { CarriersName } from "types/carriers.types"

export const submitRating = gql`
  mutation submitRating($rating: SubmitRatingInput!) {
    submitRating(rating: $rating) {
      id
    }
  }
`

export interface SubmitRatingInput {
  rating: {
    orderId: string
    appSessionId: string
    rate: number
    version: RatingVersions
  }
}

export const updateRating = gql`
  mutation updateRating($rating: UpdateRatingInput!) {
    updateRating(rating: $rating) {
      id
    }
  }
`

export interface UpdateRatingInput {
  rating: {
    orderId: string
    appSessionId: string
    rate_keeper: number
    feedback: string
    feedback_keeper: string
    answers: AnswerInput[]
    nps: number
    carrierName: CarriersName
  }
}
