import React, { ReactElement } from "react"
import { bottomToTopItem } from "modules/shared/animations/variants"
import { FormProps } from "types/rating.types"
import { colissimoFormData } from "modules/shared/datas/RatingDatas"
import {
  Message,
  QuestionContainer,
  QuestionTitleThemeColorBold,
  RatingContainer,
  RatingItemText,
  RatingValues,
} from "modules/rating/screens/Rating.styled"

const DeliveryMethod = ({ ratingState, handleRatingUpdate, getAnimateProperties }: FormProps): ReactElement => {
  return (
    <>
      <Message>
        Plus globalement, vous diriez que <b>Pickme est un mode de livraison...</b>
      </Message>

      <RatingContainer>
        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q11.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q11.labels.map(item => (
              <RatingItemText
                onClick={() => handleRatingUpdate(ratingState.q11, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q11, item.value)}
              >
                {item.label}
              </RatingItemText>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q12.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q12.labels.map(item => (
              <RatingItemText
                onClick={() => handleRatingUpdate(ratingState.q12, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q12, item.value)}
              >
                {item.label}
              </RatingItemText>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q13.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q13.labels.map(item => (
              <RatingItemText
                onClick={() => handleRatingUpdate(ratingState.q13, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q13, item.value)}
              >
                {item.label}
              </RatingItemText>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q14.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q14.labels.map(item => (
              <RatingItemText
                onClick={() => handleRatingUpdate(ratingState.q14, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q14, item.value)}
              >
                {item.label}
              </RatingItemText>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q15.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q15.labels.map(item => (
              <RatingItemText
                onClick={() => handleRatingUpdate(ratingState.q15, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q15, item.value)}
              >
                {item.label}
              </RatingItemText>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q16.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q16.labels.map(item => (
              <RatingItemText
                onClick={() => handleRatingUpdate(ratingState.q16, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q16, item.value)}
              >
                {item.label}
              </RatingItemText>
            ))}
          </RatingValues>
        </QuestionContainer>
      </RatingContainer>
    </>
  )
}

export default DeliveryMethod
