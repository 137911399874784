const TOKEN_NAME = "authToken"

export type UseAuthTokenType = {
  authToken: string
  setAuthToken: (authToken: string) => void
  removeAuthToken: () => void
}

export const useAuthToken = (): UseAuthTokenType => {
  const authToken = localStorage.getItem(TOKEN_NAME)
  const setAuthToken = (authToken: string) => localStorage.setItem(TOKEN_NAME, authToken)
  const removeAuthToken = () => localStorage.removeItem(TOKEN_NAME)

  return { authToken, setAuthToken, removeAuthToken }
}
