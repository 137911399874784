import { AnswerType, RatingState } from "types/rating.types"

export const initialQuestionColissimoRatingState = (): RatingState => ({
  q1: { order: 1, type: AnswerType.FREE_TEXT, note: undefined },
  q2: { order: 2, type: AnswerType.CHOICE, note: undefined },
  q3: { order: 3, type: AnswerType.AGREE_DISAGREE, note: undefined },
  q4: { order: 4, type: AnswerType.CHOICE, note: undefined },
  q5: { order: 5, type: AnswerType.AGREE_DISAGREE, note: undefined },
})

export const initialQuestionStandardRatingState = (): RatingState => ({
  q1: { order: 1, type: AnswerType.CHOICE, note: undefined },
  q2: { order: 2, type: AnswerType.CHOICE, note: undefined },
  q3: {
    order: 3,
    type: AnswerType.AGREE_DISAGREE,
    note: undefined,
  },
})
