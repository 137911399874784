import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

import reducer from "reducers"
import { configureStore } from "@reduxjs/toolkit"

const persistConfig = {
  key: "root",
  version: 0,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["ThemeOptions"],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
  const persistor = persistStore(store)

  return { store, persistor }
}
