import React, { ReactElement, useCallback, useState } from "react"
import { Redirect } from "react-router-dom"
import { useMutation } from "@apollo/client"
import * as userMutations from "services/graphql/mutations/user.mutations"
import { loginSchema } from "schema/login.schema"
import { Form, Field, Formik } from "formik"
import Logo from "assets/logos/pickme-logo.png"
import { ConnectButton } from "modules/Carriers/components/Buttons/ConnectButton/ConnectButton"
import { InputText } from "modules/Carriers/components/InputText/InputText"
import { useAuthToken } from "hooks/useAuthToken"
import { LoginData, LoginVariables } from "services/graphql/mutations/user.mutations"
import { LoadingComponent } from "modules/shared/components/LoadingComponent/LoadingComponent"
import { useCheckUser } from "hooks/useCheckUser"
import { LoginContainer, LogoContainer, LogoImage, PageContainer } from "modules/Carriers/components/Login/Login.styled"

export const Login = (): ReactElement => {
  const [login] = useMutation<LoginData, LoginVariables>(userMutations.login)
  const [authError, setAuthError] = useState(false)
  const [authSuccess, setAuthSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setAuthToken } = useAuthToken()
  const { logged, user } = useCheckUser(true)

  const submit = useCallback(async ({ email, password }) => {
    setAuthError(false)
    setLoading(true)

    try {
      const data = await login({
        variables: {
          email,
          password,
        },
      })

      setAuthToken(data.data.companyUserLogin.token.accessToken)
      setAuthSuccess(true)
      setLoading(false)
    } catch (e) {
      setAuthError(true)
      setLoading(false)
    }
  }, [])

  if (authSuccess || (logged && user !== null)) return <Redirect to="/carriers" />

  return (
    <>
      <PageContainer>
        <LoginContainer>
          <LogoContainer>
            <LogoImage src={Logo} className="logo-solo" alt={"logo"} />
          </LogoContainer>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={values => submit(values)}
          >
            {() => (
              <Form className="login-box-form">
                <div className="login-box-top">
                  <Field type="email" name="email" placeholder="Email" component={InputText} />
                  <Field type="password" name="password" placeholder="Mot de passe" component={InputText} />
                  {loading && <LoadingComponent paddingBottom={"2em"} paddingTop={"0"} />}
                  {authError && <div className="login-error">Erreur d&apos;authentification</div>}

                  <ConnectButton text="Se connecter" />
                </div>
              </Form>
            )}
          </Formik>
        </LoginContainer>
      </PageContainer>
    </>
  )
}
