import { Order } from "models/order.model"
import { ButtonWithIcon } from "modules/shared/components/Button/Button"
import { Input } from "modules/shared/components/Input/Input"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import { CarriersName } from "types/carriers.types"

interface FeedbackProps {
  order: Order
  submit: (feedback: string) => void
}

export const Feedback = ({ order, submit }: FeedbackProps): ReactElement => {
  const [feedback, setFeedback] = useState<string>("")

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  /** Indirect reference to the feedback variable */
  const feedbackRef = useRef<string>("")
  const minCharacters = 0

  function isFeedbackValid(feedback: string): boolean {
    return order.deliveryCarrierName === CarriersName.COLISSIMO ? feedback?.length > minCharacters : true
  }

  feedbackRef.current = feedback

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current && feedbackRef.current) {
        submit(feedbackRef.current)
      }
    }
  }, [])

  function getTitle(): string | ReactElement {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return "Pour terminer, n'hésitez pas à nous faire part de vos suggestions d'amélioration."

      default:
        return "Dites nous en plus 😉"
    }
  }

  function getSubTitle(): string | ReactElement {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return ""
      default:
        return (
          <>
            🥇 Quel est le <b>principal avantage</b> que vous avez trouvé à utiliser Pickme ?{" "}
          </>
        )
    }
  }

  function getDescription(): string | ReactElement {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return ""
      default:
        return (
          <>
            🛠 Comment pouvons-nous <b>améliorer Pickme</b> pour vous ?
          </>
        )
    }
  }

  function getButtonText(): string {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return "Terminer"
      default:
        return "Suivant"
    }
  }

  function getInputPlaceholder(): string {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return ""
      default:
        return "Expérience simple, livreur agréable, ..."
    }
  }

  const subtitle = getSubTitle()
  const description = getDescription()

  return (
    <PeerChallengeFeedbackContainer>
      <div className="step-container">
        <div className="action-container">
          <div className="feedback-information">
            <div className="title">{getTitle()}</div>
          </div>
          <br />
          {subtitle && <p>{getSubTitle()}</p>}
          {description && <p>{getDescription()}</p>}
          <br />
          <div className="feedback">
            <div className="feedback-editor">
              <Input
                placeholder={getInputPlaceholder()}
                behavior="textarea"
                onChange={text => setFeedback(text)}
                value={feedback}
              />
            </div>
          </div>

          <div className="cta-bloc">
            <div className="cta-container">
              <ButtonWithIcon
                text={getButtonText()}
                icon={null}
                disabled={!isFeedbackValid(feedback)}
                onClick={() => {
                  submit(feedback)
                  isDataSent.current = true
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PeerChallengeFeedbackContainer>
  )
}
