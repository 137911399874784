import { ReactElement, ReactNode, useEffect, useState } from "react"
import React from "react"
import cx from "classnames"
import { ReduxState } from "types/reduxState.types"

import ResizeDetector from "react-resize-detector"
import { useSelector } from "react-redux"

interface MainProps {
  children: ReactNode
}

export const Main = ({ children }: MainProps): ReactElement => {
  const [width, setWidth] = useState(undefined)
  const [closedSmallerSidebar] = useState(false)

  const onResize = (newWidth: string) => {
    setWidth(newWidth)
  }

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
  } = useSelector((state: ReduxState) => state.ThemeOptions)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div
      className={cx(
        `app-container app-theme-${colorScheme}`,
        { "fixed-header": enableFixedHeader },
        { "fixed-sidebar": enableFixedSidebar || width < 1250 },
        { "fixed-footer": enableFixedFooter },
        { "closed-sidebar": enableClosedSidebar || width < 1250 },
        { "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
        { "sidebar-mobile-open": enableMobileMenu },
      )}
    >
      {children}
      <ResizeDetector key={1} handleWidth onResize={onResize} />
    </div>
  )
}
