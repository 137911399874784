import { combineReducers } from "redux"
import ThemeOptions from "reducers/ThemeOptions"
import metisMenuReducer from "react-metismenu/lib/reducers"

const appReducer = combineReducers({
  ThemeOptions,
  metisMenuStore: metisMenuReducer,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
