import styled from "styled-components"
import { boxShadowColor, darkGrey, green, mediumGreen, mediumLightGrey, white } from "utils/color"
import { StyledInput } from "modules/shared/components/Input/Input.styled"
import { Form } from "formik"
import { Col } from "reactstrap"

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${white};
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 ${boxShadowColor};
  padding: 9px 20px;
`

export const ButtonContainer = styled.div`
  width: fit-content;
  margin-left: auto;
`

export const MiniButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const MiniButton = styled.button`
  color: ${white};
  font-family: Poppins;
  font-weight: 600;
  padding: 5px 12px;
  margin-left: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 10px;
  position: relative;
  background-color: ${green};
  width: fit-content;
  border: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${mediumGreen};
  }

  &:disabled {
    background-color: ${mediumLightGrey};
    opacity: 0.65;
    cursor: auto;
  }
`

export const LinkButton = styled.button`
  color: ${darkGrey};
  font-family: Poppins;
  padding: 5px 12px;
  margin-left: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 10px;
  position: relative;
  width: fit-content;
  border: none;
  font-weight: 400;
  line-height: 15px;
  text-decoration: underline;
  background-color: transparent;
`

export const LineElementInput = styled(StyledInput)`
  height: 35px;
  box-shadow: none;
  background-color: ${white};

  input {
    border: none;
    background-color: ${white};
    font-size: 12px;
    &:focus {
      box-shadow: none;
    }
  }
`

export const ParcelNo = styled.p`
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${darkGrey};
  font-weight: 600;
  font-family: Poppins;
  margin: 0;
`

export const CarriersForm = styled(Form)`
  width: 100%;
`

export const CustomCol = styled(Col)`
  overflow: hidden;
  @media (max-width: 700px) {
    width: 100%;
    max-width: 100%;
  }
`

export const CustomRowCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DiffDays = styled.span<{ color: string }>`
  font-family: Poppins;
  font-size: 9px;
  font-weight: 400;
  line-height: 10.5px;
  text-align: left;
  color: ${props => props.color || darkGrey};
`
