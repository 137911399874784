import { AnswerInput } from "types/rating.types"

/**
 * Merges two arrays of answers, removing duplicates based on the 'order' property.
 * Prioritizes the items from the 'newAnswers' array in case of duplicates.
 * Returns a new array sorted by the 'order' property.
 */
export const mergeAndRemoveDuplicates = (oldAnswers: AnswerInput[], newAnswers: AnswerInput[]): AnswerInput[] => {
  const merged = [...newAnswers, ...oldAnswers]
  const seen = new Set()

  return merged
    .reduce((acc, item) => {
      if (!seen.has(item.order)) {
        seen.add(item.order)
        acc.unshift(item)
      }
      return acc
    }, [])
    .sort((answer1, answer2) => answer1.order - answer2.order)
}
