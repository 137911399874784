import React, { ReactElement } from "react"
import { Redirect, Route, useLocation } from "react-router-dom"
import { Loader } from "semantic-ui-react"
import "semantic-ui-css/semantic.min.css"
import { useCheckUser } from "hooks/useCheckUser"
import { Main } from "modules/Carriers/components/AppStructure/Main/Main"
import { AppSidebar } from "modules/Carriers/components/AppStructure/AppSidebar"
import { getRoutingConfig } from "config/routing"
import { AppHeader } from "modules/Carriers/components/AppStructure/AppHeader"
import { AppFooter } from "modules/Carriers/components/AppStructure/AppFooter"
import { Slide } from "react-toastify"
import { Container, StyledToastContainer } from "modules/Carriers/components/CarriersHome.styled"

export const CarriersHome = (): ReactElement => {
  const location = useLocation()
  const { logged, user, ready, logout } = useCheckUser(true)

  if (!ready) return <Loader active />

  if (logged && user !== null) {
    return (
      <Container>
        <Main>
          <AppHeader logout={logout} />
          <div className="app-main">
            <AppSidebar />
            <div className="app-main__outer">
              <div className="app-main__inner">
                {getRoutingConfig().map(route => {
                  return <RouteWithSubRoutes {...route} key={`route${route.path}`} />
                })}
                {location.pathname === "/carriers" && <Redirect to="/carriers/parcels-without-contact" />}
              </div>
              <AppFooter />
            </div>
          </div>
        </Main>

        <StyledToastContainer position="top-right" autoClose={3000} hideProgressBar transition={Slide} />
      </Container>
    )
  }

  return <Redirect to="/carriers/login" />
}

function RouteWithSubRoutes(route): ReactElement {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      {...route.props}
      render={matchProps => (
        // pass the sub-routes down to keep nesting
        <route.component {...matchProps} routes={route.routes} />
      )}
    />
  )
}
