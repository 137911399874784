import React, { ReactElement } from "react"
import { Form as SemForm } from "semantic-ui-react"
import { errorStyle } from "modules/Carriers/components/InputText/InputText.styled"

export const InputText = ({ field, form: { touched, errors }, placeholder, ...props }): ReactElement => (
  <>
    <SemForm.Input {...field} {...props} fluid placeholder={placeholder} style={{ marginBottom: 20 }} />
    {touched[field.name] && errors[field.name] && <div style={errorStyle}>{errors[field.name]}</div>}
  </>
)
