import { Emoji } from "modules/shared/components/Emoji"
import React from "react"
import { ReactElement } from "react"
import { pickmeWebSite } from "utils/links"
import PickmeLogo from "assets/logos/pickme-logo.png"
import { ErrorContainer, ErrorMessage, ErrorMessageContainer, ErrorPickmeLogoContainer } from "./DestiChat.styled"

export const Error = (): ReactElement => (
  <ErrorContainer>
    <ErrorMessageContainer>
      <a href={pickmeWebSite}>
        <ErrorPickmeLogoContainer alt="logoo pickme" src={PickmeLogo} />
      </a>
      <Emoji label="slightly frowning face" size="extra-big" style={{ marginBottom: "30px" }}>
        🙁
      </Emoji>
      <ErrorMessage>{"Aucune messagerie n'est disponible pour cette commande..."}</ErrorMessage>
    </ErrorMessageContainer>
  </ErrorContainer>
)
