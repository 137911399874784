import React, { ReactElement } from "react"
import { bottomToTopItem } from "modules/shared/animations/variants"
import { FormProps } from "types/rating.types"
import { colissimoFormData } from "modules/shared/datas/RatingDatas"
import {
  Message,
  MessageSpacing,
  MessageWidth,
  QuestionContainer,
  QuestionContainerV2,
  QuestionContainerV2Spacing,
  QuestionItemText,
  QuestionValues,
} from "modules/rating/screens/Rating.styled"

const ChooseAgain = ({ ratingState, handleRatingUpdate, getAnimateProperties }: FormProps): ReactElement => {
  return (
    <>
      <MessageWidth>
        <MessageSpacing>
          <Message>{colissimoFormData.q18.title}</Message>
        </MessageSpacing>
      </MessageWidth>

      <QuestionContainerV2>
        <QuestionContainer>
          <QuestionContainerV2Spacing>
            <QuestionValues variants={bottomToTopItem}>
              {colissimoFormData.q18.labels.map(item => (
                <QuestionItemText
                  onClick={() => handleRatingUpdate(ratingState.q18, item.value)}
                  whileTap={{
                    scale: 0.9,
                  }}
                  key={item.value}
                  animate={getAnimateProperties(ratingState.q18, item.value)}
                >
                  {item.label}
                </QuestionItemText>
              ))}
            </QuestionValues>
          </QuestionContainerV2Spacing>
        </QuestionContainer>
      </QuestionContainerV2>
    </>
  )
}

export default ChooseAgain
