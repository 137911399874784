import React, { ReactElement, useEffect, useRef, useState } from "react"
import { Order } from "models/order.model"
import { bottomToTopItem, topToBottom } from "modules/shared/animations/variants"
import { ButtonWithIcon } from "modules/shared/components/Button/Button"
import { FiArrowRight } from "react-icons/fi"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import "modules/rating/screens/styles.scss"
import { corail, oldGreen, grey, yellow, white } from "utils/color"
import { isUndefined } from "utils/isUndefined"
import {
  ActionContainer,
  CtaBlocBottom,
  CtaContainer,
  MessageGray,
  MessageMotion,
  PeerRating,
  RatingContainer,
  RatingItem,
  RatingValues,
  StepContainer,
  Subquestion,
  Text,
} from "modules/rating/screens/Rating.styled"
import { showBanner } from "utils/showBanner"
import RateBanner from "modules/shared/components/Banner/Banner"
import { CarriersName } from "types/carriers.types"
import { backgroundGrey } from "theme/colors"
import { OUT_OF_10 } from "modules/shared/datas/RatingDatas"

interface RateProps {
  order: Order
  onRateUpdated: (rate: number) => void
}

export const Rate = ({ order, onRateUpdated }: RateProps): ReactElement => {
  const [ratingState, setRatingState] = useState<number>(undefined)

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  /** Indirect reference to the ratingState variable */
  const ratingStateRef = useRef<number>(undefined)

  const isBanner =
    order.deliveryCarrierName === CarriersName.COLISSIMO && showBanner(order?.address?.keeper?.address?.zipcode)

  const isDisabled = isUndefined(ratingState)

  const getRatingItemBackground = (item: number): string => {
    const selected = item === ratingState

    switch (item) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return selected ? corail : "rgba(252, 92, 101, 0.5)"
      case 7:
      case 8:
        return selected ? yellow : "rgba(254, 211, 48, 0.5)"
      case 9:
      case 10:
        return selected ? oldGreen : "rgba(38, 222, 129, 0.5)"
      default:
        return white
    }
  }

  function getButtonBackgroundColor(item: number): string {
    const selected = item === ratingState

    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return selected ? corail : backgroundGrey
      default:
        return getRatingItemBackground(item)
    }
  }

  function getTitle(): string | ReactElement {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return (
          <>
            Vous avez réceptionné votre <b>Colissimo</b> chez votre Voisin-Relais{" "}
            <b>{order?.address?.keeper?.firstName}</b>
          </>
        )
      default:
        return (
          <>
            Votre Voisin-Relais <b>{order?.address?.keeper?.firstName}</b> a réceptionné pour vous un colis !
          </>
        )
    }
  }

  function getSubTitle(): string | ReactElement {
    switch (order.deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return <>Avez-vous été satisfait(e) de la livraison de votre Colissimo par Pickme ?</>
      default:
        return (
          <>
            Comment s&apos;est passée votre réception ? Qu&apos;avez-vous pensé de ce service de réception chez un
            Voisin-Relais particulier ?
          </>
        )
    }
  }

  ratingStateRef.current = ratingState

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current && !isUndefined(ratingStateRef.current)) {
        onRateUpdated(ratingStateRef.current)
      }
    }
  }, [])

  return (
    <PeerChallengeFeedbackContainer>
      <StepContainer>
        {isBanner && <RateBanner />}
        <ActionContainer marginTop={isBanner ? 18 : 90}>
          <>
            <PeerRating>
              <MessageMotion variants={topToBottom}>
                <Text>{getTitle()}</Text>
                <Subquestion>{getSubTitle()}</Subquestion>
                <MessageGray>
                  0 signifie que vous n&apos;êtes pas du tout satisfait(e), 10 signifie que vous êtes très satisfait(e)
                </MessageGray>
              </MessageMotion>
              <RatingContainer>
                <RatingValues variants={bottomToTopItem}>
                  {OUT_OF_10.map(item => (
                    <RatingItem
                      onClick={() => setRatingState(item)}
                      whileTap={{
                        scale: 0.9,
                      }}
                      key={item}
                      animate={{
                        background: getButtonBackgroundColor(item),
                        color: ratingState === item ? white : grey,
                      }}
                    >
                      {item}
                    </RatingItem>
                  ))}
                </RatingValues>
              </RatingContainer>
            </PeerRating>
          </>
          <CtaBlocBottom>
            <CtaContainer>
              <ButtonWithIcon
                text="Suivant"
                disabled={isDisabled}
                icon={<FiArrowRight />}
                onClick={() => {
                  onRateUpdated(ratingState)
                  isDataSent.current = true
                }}
              />
            </CtaContainer>
          </CtaBlocBottom>
        </ActionContainer>
      </StepContainer>
    </PeerChallengeFeedbackContainer>
  )
}
