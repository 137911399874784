import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { useScript } from "hooks/useScript"
import { StyledDivBot, hmbShadowStyles } from "modules/HelloMyBot/HelloMyBot.styled"

export const HelloMyBot = () => {
  const { keeperId, parcelNumber } = useParams<{ keeperId: string; parcelNumber: string }>()
  // Use a different script for production environment
  const prefixUrl = process.env.REACT_APP_STAGE === "production" ? "" : "dev."

  useScript(`https://${prefixUrl}botcore.hellomybot.io/v2/bundle`, keeperId, parcelNumber)

  // Apply custom styles to the bot
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      const shadowRoot = document.querySelector(`#hmb-widget-${process.env.REACT_APP_HMBOT_KEY}`)?.shadowRoot

      // eslint-disable-next-line no-undef
      const styleTag = document.createElement("style")
      styleTag.innerHTML = hmbShadowStyles
      shadowRoot.appendChild(styleTag)
    }, 1000)
  }, [])

  return <StyledDivBot id="divBot" />
}
