import React, { ReactElement, ReactNode } from "react"
import Div100vh from "react-div-100vh"

interface FullScreenContainerProps {
  children: ReactNode
}

const FullScreenContainer = ({ children }: FullScreenContainerProps): ReactElement => <Div100vh>{children}</Div100vh>

export default FullScreenContainer
