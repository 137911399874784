import styled from "styled-components"

import { WhiteCard } from "modules/shared/components/WhiteCard.styled"
import { white } from "utils/color"
import { devices, fontSizes } from "utils/devices"

export const OrderInfoContainer = styled.div`
  width: 30%;
  margin-right: 15px;

  @media ${devices.mobile} {
    width: 100%;
  }
`

export const OrderInfosCard = styled(WhiteCard)`
  display: flex;
  flex-direction: column;

  @media ${devices.mobile} {
    padding: 10px;
    flex-direction: row;
    padding-right: 15px;
  }
`

export const OrderDescription = styled.div`
  font-size: ${fontSizes.desktop.medium};

  @media ${devices.mobile} {
    font-size: ${fontSizes.mobile.small};
  }
`

export const OrderDetailsContainer = styled.div`
  margin-top: 30px;

  @media ${devices.mobile} {
    margin-top: 0px;
    margin-left: auto;
  }
`

export const CarrierLogo = styled.img`
  height: 40px;
  margin-bottom: 10px;

  @media ${devices.mobile} {
    height: 30px;
    margin-bottom: 0;
    margin-left: auto;
  }
`

export const KeeperDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const KeeperPictureContainer = styled.div`
  width: 48px;
  height: 48px;
  background-image: linear-gradient(138deg, #fd777e 14%, #ffae8b 85%);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AvatarContainer = `
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid ${white};
`

export const KeeperAvatarContainer = styled.div`
  ${AvatarContainer}
`

export const KeeperAvatar = styled.img`
  width: auto;
  height: 100%;
`

export const KeeperNoAvatarContainer = styled.div`
  ${AvatarContainer}
  display: flex;
  justify-content: center;
  align-items: center;
`

export const KeeperNoAvatar = styled.img`
  height: 20px;
`

export const KeeperDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media ${devices.mobile} {
    margin-left: 5px;
  }
`

export const KeeperName = styled.p`
  font-weight: 600;
  font-size: ${fontSizes.desktop.medium};

  @media ${devices.mobile} {
    font-size: ${fontSizes.mobile.medium};
  }
`

export const KeeperSubtitle = styled.p`
  font-size: ${fontSizes.desktop.regular};

  @media ${devices.mobile} {
    font-size: ${fontSizes.mobile.small};
  }
`
