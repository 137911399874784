import { gql } from "apollo-boost"

/*
 * @Description : Get an order
 * @Returns : Order
 * @ErrorIf : -
 */
export const getOrder = gql`
  query($id: String!) {
    orderByIdForRating(id: $id) {
      id
      orderNo
      status
      progressionStatus
      recipient {
        firstName
        lastName
        email
        phone
      }
      address {
        id
        keeper {
          firstName
          address {
            zipcode
          }
        }
      }
      alreadyRated
      deliveryCarrierName
    }
  }
`

/*
 * @Description : Get Order chat data
 * @Returns : OrderChat
 * @ErrorIf : -
 */
export const getOrderChatData = gql`
  query($orderId: String!) {
    getOrderChatData(orderId: $orderId) {
      orderId
      orderStatus
      chatAccessRights
      chatId
      parcelId
      parcelNo
      keeperFirstName
      keeperPictureUrl
      shipperName
      carrierName
      chatSignature
    }
  }
`
