import React, { ReactElement } from "react"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"

import { DestiChat } from "modules/DestiChat/DestiChat"
import KeeperRatingRoutes from "modules/rating/KeeperRatingRoutes"
import ScrollToTop from "modules/shared/components/ScrollToTop/ScrollToTop"
import { HelloMyBot } from "modules/HelloMyBot/HelloMyBot"
import { Login } from "modules/Carriers/components/Login/Login"
import { CarriersHome } from "modules/Carriers/components/CarriersHome"

export default function Routes(): ReactElement {
  return (
    <Router>
      <ScrollToTop />
      <Route
        render={({ location }) => {
          return (
            <Switch location={location}>
              <Route component={KeeperRatingRoutes} path="/rating/:orderId?" />
              <Route component={DestiChat} path="/chat/:orderId?" />
              <Route component={HelloMyBot} path="/hellomybot/:keeperId/:parcelNumber?" />
              <Route component={Login} path="/carriers/login" />
              <Route component={CarriersHome} path="/carriers" />
            </Switch>
          )
        }}
      />
    </Router>
  )
}
