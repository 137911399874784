import styled from "styled-components"
import { ghostWhite } from "utils/color"

export const PageContainer = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ghostWhite};
`

export const LoginContainer = styled.div`
  width: 500px;
`

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`

export const LogoImage = styled.img`
  width: 450px;
`
