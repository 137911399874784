export enum ParcelEventCode {
  RECIPIENT_CONNECTED_ON_CHAT = "RECIPIENT_CONNECTED_ON_CHAT",
  RECIPIENT_CLICK_SUPPORT_LINK = "RECIPIENT_CLICK_SUPPORT_LINK",
}
export enum ParcelEventReason {
  CLICK_CTA = "CLICK_CTA",
  SCREEN_VIEW = "SCREEN_VIEW",
}

export interface SaveParcelEventInput {
  parcelId: string
  title: string
  description: string
  parcelEventCode: ParcelEventCode
  parcelEventReason: ParcelEventReason
}
