import { TitleData } from "modules/rating/screens/Rating.styled"
import React from "react"

export const OUT_OF_10 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const AGREE_DISAGREE = [
  { label: "Oui", value: 1 },
  { label: "Non", value: 2 },
]

export const standardFormData = {
  q1: {
    title: "Comment vous êtes vous déplacé jusqu'à votre Voisin-Relais ?",
    labels: [
      { label: "À pied 🚶‍♂️", value: 1 },
      { label: "En vélo 🚲", value: 2 },
      { label: "En transports en commun 🚍", value: 3 },
      { label: "En voiture 🚗", value: 4 },
    ],
  },
  q2: {
    title: "À quelle distance environ se situait le Voisin-Relais de votre domicile ?",
    labels: [
      { label: "Moins de 200m", value: 1 },
      { label: "Entre 200m et 500m", value: 2 },
      { label: "Entre 500m et 1km", value: 3 },
      { label: "Entre 1km et 2km", value: 4 },
      { label: "Plus de 2km", value: 5 },
    ],
  },
  q3: {
    title: (
      <TitleData>
        <b>Aviez-vous déjà entendu parler de Pickme</b> avant cette livraison ?
      </TitleData>
    ),
    labels: AGREE_DISAGREE,
  },
  q4: {
    title: "",
    labels: [
      { label: "1€", value: 1 },
      { label: "3€", value: 3 },
      { label: "5€", value: 5 },
      { label: "10€", value: 10 },
    ],
  },
}

export const colissimoFormData = {
  q1: {
    title: "Pour quelle raison principale avez-vous donné cette note de",
    labels: [],
  },
  q2: {
    title: (
      <TitleData>
        Quel <b>niveau d’effort</b> avez-vous du fournir pour récupérer votre colis ?
      </TitleData>
    ),
    labels: [
      { label: "Très peu d’effort️", value: 1 },
      { label: "Peu d’effort", value: 2 },
      { label: "Effort acceptable", value: 3 },
      { label: "Effort important", value: 4 },
      { label: "Effort très important", value: 5 },
    ],
  },
  q3: {
    title: (
      <TitleData>
        Vous attendiez-vous à réceptionner votre <b>Colissimo</b> chez un <b>particulier</b> ?
      </TitleData>
    ),
    labels: AGREE_DISAGREE,
  },
  q4: {
    title: "Et diriez-vous que cela a été pour vous une expérience...",
    labels: [
      { label: "Très agréable", value: 1 },
      { label: "Plutôt agréable", value: 2 },
      { label: "Plutôt désagréable", value: 3 },
      { label: "Très désagréable", value: 4 },
    ],
  },
  q5: {
    title: (
      <TitleData>
        Avez-vous réceptionné votre <b>Colissimo</b> chez le particulier que vous aviez choisi à la commande ?
      </TitleData>
    ),
    labels: AGREE_DISAGREE,
  },
  q6: { title: "👉 La clarté des différentes notifications reçues par mail ou SMS", labels: OUT_OF_10 },
  q7: { title: "👉 La prise de rendez-vous", labels: OUT_OF_10 },
  q8: { title: "👉 La disponibilité du particulier en termes de jour et d’horaire", labels: OUT_OF_10 },
  q9: { title: "👉 La proximité géographique", labels: OUT_OF_10 },
  q10: { title: "👉 L’accueil", labels: OUT_OF_10 },
  q11: {
    title: "👉 Économique",
    labels: [
      { label: "Tout à fait d’accord", value: 1 },
      { label: "Assez d’accord", value: 2 },
      { label: "Pas vraiment d’accord", value: 3 },
      { label: "Pas du tout d’accord", value: 4 },
    ],
  },
  q12: {
    title: "👉 Écologique",
    labels: [
      { label: "Tout à fait d’accord", value: 1 },
      { label: "Assez d’accord", value: 2 },
      { label: "Pas vraiment d’accord", value: 3 },
      { label: "Pas du tout d’accord", value: 4 },
    ],
  },
  q13: {
    title: "👉 Qui permet de créer du lien social dans mon quartier",
    labels: [
      { label: "Tout à fait d’accord", value: 1 },
      { label: "Assez d’accord", value: 2 },
      { label: "Pas vraiment d’accord", value: 3 },
      { label: "Pas du tout d’accord", value: 4 },
    ],
  },
  q14: {
    title: "👉 Sécurisant pour mon colis",
    labels: [
      { label: "Tout à fait d’accord", value: 1 },
      { label: "Assez d’accord", value: 2 },
      { label: "Pas vraiment d’accord", value: 3 },
      { label: "Pas du tout d’accord", value: 4 },
    ],
  },
  q15: {
    title: "👉 Qui développe l’économie sociale et solidaire entre voisins",
    labels: [
      { label: "Tout à fait d’accord", value: 1 },
      { label: "Assez d’accord", value: 2 },
      { label: "Pas vraiment d’accord", value: 3 },
      { label: "Pas du tout d’accord", value: 4 },
    ],
  },
  q16: {
    title: "👉 Qui permet de récupérer plus rapidement mon colis",
    labels: [
      { label: "Tout à fait d’accord", value: 1 },
      { label: "Assez d’accord", value: 2 },
      { label: "Pas vraiment d’accord", value: 3 },
      { label: "Pas du tout d’accord", value: 4 },
    ],
  },
  q17: {
    title: (
      <TitleData>
        <b>Aviez-vous déjà entendu parler de Pickme</b> avant cette livraison ?
      </TitleData>
    ),
    labels: AGREE_DISAGREE,
  },
  q18: {
    title: (
      <TitleData>
        Avez vous l’intention de <b>choisir à nouveau ce mode de livraison</b> chez un particulier pour la livraison de
        prochains achats en ligne ?
      </TitleData>
    ),
    labels: [
      { label: "Oui certainement", value: 1 },
      { label: "Oui probablement", value: 2 },
      { label: "Non probablement pas", value: 3 },
      { label: "Non certainement pas", value: 4 },
    ],
  },
}
