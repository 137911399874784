import React, { useMemo, useState } from "react"
import { Field, Formik } from "formik"
import { updateOrderRecipientSchema } from "schema/updateParcelsWithoutRecipientCordinates.schema"

import { LineElementRow } from "modules/Carriers/components/Table/LineElementRow/LineElementRow.styled"
import { Loader, Popup } from "semantic-ui-react"
import { LineElementCol } from "modules/Carriers/components/Table/LineElementCol/LineElementCol"
import { ClassicInput } from "modules/Carriers/components/ClassicInput"
import {
  ButtonContainer,
  CarriersForm,
  Container,
  CustomCol,
  DiffDays,
  CustomRowCol,
  LineElementInput,
  LinkButton,
  MiniButton,
  MiniButtonContainer,
  ParcelNo,
} from "modules/Carriers/components/ParcelLine/ParcelLine.styled"
import * as parcelMutations from "services/graphql/mutations/parcel.mutations"
import { useAppApolloClient } from "services/graphql/apollo-client"
import {
  SaveRecipientCoordinatesFromCarrierVariables,
  SendNoRecipientDetailsAvailableVariables,
} from "services/graphql/mutations/parcel.mutations"
import { UnannouncedParcelState } from "types/order.types"
import { toast, ToastOptions } from "react-toastify"
import { nobelGrey, red } from "utils/color"
import { CancelModal } from "../CancelModal/CancelModal"

interface ParcelLineProps {
  parcel: UnannouncedParcelState
  fetchUnannouncedParcels: () => void
}

const dayDurationMs = 1000 * 60 * 60 * 24

export const ParcelLine = ({ parcel, fetchUnannouncedParcels }: ParcelLineProps) => {
  const apolloClient = useAppApolloClient()

  const [isLoading, setIsLoading] = useState(false)
  const [parcelNoCopied, setParcelNoCopied] = useState(false)

  const toastConfig: ToastOptions = {
    icon: false,
    hideProgressBar: true,
    progressStyle: { opacity: 0 },
    style: { animationFillMode: "forwards", animationDuration: "450ms" },
  }

  const onRemoveRequestFlag = async () => {
    setIsLoading(true)

    await apolloClient
      .mutate<boolean, SendNoRecipientDetailsAvailableVariables>({
        mutation: parcelMutations.noRecipientDetailsAvailable,
        variables: { parcelId: parcel.id },
      })
      .then(() => {
        fetchUnannouncedParcels()
        toast.success("Le colis a été mis à jour", { ...toastConfig })
      })
      .catch(() => {
        toast.error("Quelque chose s'est mal passé.", { ...toastConfig })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const submit = async (values: UnannouncedParcelState) => {
    setIsLoading(true)

    const variables: SaveRecipientCoordinatesFromCarrierVariables = {
      orderId: values.orderId,
      recipient: {
        firstName: values.firstName,
        ...(values.lastName && { lastName: values.lastName }),
        ...(values.phone && { phone: values.phone }),
        ...(values.email && { email: values.email }),
      },
    }

    await apolloClient
      .mutate<boolean, SaveRecipientCoordinatesFromCarrierVariables>({
        mutation: parcelMutations.saveRecipientCoordinatesFromCarrier,
        variables,
      })
      .then(() => {
        setIsLoading(false)
        fetchUnannouncedParcels()
        toast.success("Le colis a été mis à jour", { ...toastConfig })
      })
      .catch(() => {
        setIsLoading(false)
        toast.error("Quelque chose s'est mal passé.", { ...toastConfig })
      })
  }

  const _copyParcelNo = async (e: React.MouseEvent<HTMLElement>, parcelNo: string) => {
    e.preventDefault()

    setParcelNoCopied(true)
    await navigator.clipboard.writeText(parcelNo)

    setTimeout(() => {
      setParcelNoCopied(false)
    }, 2000)
  }

  const renderDiffDays = useMemo(() => {
    const today = new Date()
    const createdAtDate = new Date(parcel.createdAt)

    today.setUTCHours(0, 0, 0, 0)
    createdAtDate.setUTCHours(0, 0, 0, 0)

    const diffInMs = today.valueOf() - createdAtDate.valueOf()
    const diffInDays = Math.floor(diffInMs / dayDurationMs)
    const color = diffInDays < 5 ? nobelGrey : red

    return <DiffDays color={color}>{`Reçu il y a ${diffInDays} jours`}</DiffDays>
  }, [parcel.createdAt])

  return (
    <Container key={parcel.parcelNo}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={parcel}
        validationSchema={updateOrderRecipientSchema}
        onSubmit={values => submit(values)}
      >
        {({ submitForm, isValid }) => (
          <CarriersForm>
            <LineElementRow>
              <CustomCol xs={2}>
                <Popup
                  content={<ParcelNo>📦 {parcelNoCopied ? "Copié ✅" : "Copier le numéro" || ""}</ParcelNo>}
                  size={"small"}
                  position="bottom left"
                  trigger={
                    <ParcelNo onClick={(e: React.MouseEvent<HTMLElement>) => _copyParcelNo(e, parcel.parcelNo)}>
                      📦 {parcel?.parcelNo || ""}
                    </ParcelNo>
                  }
                />
                {renderDiffDays}
              </CustomCol>
              <LineElementCol
                maxWidth={"210px"}
                label={"Nom du destinataire"}
                size={2}
                content={
                  <LineElementInput>
                    <Field type="text" name="lastName" component={ClassicInput} />
                  </LineElementInput>
                }
              />
              <LineElementCol
                label={"Prénom du destinataire"}
                size={2}
                maxWidth={"210px"}
                content={
                  <LineElementInput>
                    <Field type="text" name="firstName" component={ClassicInput} />
                  </LineElementInput>
                }
              />
              <LineElementCol
                label={"📧 Email"}
                size={2}
                maxWidth={"210px"}
                content={
                  <LineElementInput>
                    <Field type="email" name="email" component={ClassicInput} />
                  </LineElementInput>
                }
              />
              <LineElementCol
                label={"📱 Téléphone"}
                size={2}
                maxWidth={"210px"}
                content={
                  <LineElementInput>
                    <Field type="text" name="phone" component={ClassicInput} />
                  </LineElementInput>
                }
              />
              <CustomRowCol xs={2}>
                <CancelModal
                  title="Êtes-vous sûr ?"
                  subtitle="Si vous ne fournissez pas les coordonnées du destinataire, Pickme ne sera pas en mesure de livrer le colis au destinataire et devrons organiser le retour du colis."
                  trigger={<LinkButton type="button">Pas d’informations</LinkButton>}
                  onConfirm={onRemoveRequestFlag}
                  isLoading={isLoading}
                />
                <ButtonContainer>
                  {isLoading ? (
                    <Loader size={"medium"} active inline />
                  ) : (
                    <MiniButtonContainer>
                      <MiniButton disabled={!isValid} onClick={submitForm}>
                        Valider
                      </MiniButton>
                    </MiniButtonContainer>
                  )}
                </ButtonContainer>
              </CustomRowCol>
            </LineElementRow>
          </CarriersForm>
        )}
      </Formik>
    </Container>
  )
}
