import React, { ReactElement } from "react"
import { Link } from "react-router-dom"

export interface NavSoloItemProps {
  content: {
    path: string
    icon: ReactElement
    label?: string
  }
  active: boolean
}

export const NavSoloItem = ({ content, active = false }: NavSoloItemProps): ReactElement => {
  return (
    <li className="metismenu-item">
      <Link to={content.path} className={`metismenu-link ${active ? "active" : ""}`}>
        {content.icon}
        {content.label}
      </Link>
    </li>
  )
}
