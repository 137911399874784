import { bottomToTopItem } from "modules/shared/animations/variants"
import React from "react"
import { FiHelpCircle } from "react-icons/fi"
import { Help, InputTextarea, Limit, MaxLimit } from "modules/shared/components/Input/Input.styled"

interface InputProps {
  behavior: "input" | "textarea"
  isNumericInput?: boolean
  onChange: (text: string) => void
  value: string
  placeholder: string
  withHelpIcon?: boolean
  onHelpRequested?: () => void
  minChar?: number
  maxChar?: number
}

const Input: React.FC<InputProps> = ({
  behavior,
  onChange,
  value,
  placeholder,
  withHelpIcon,
  onHelpRequested,
  minChar,
  maxChar,
  isNumericInput,
}) => {
  return (
    <InputTextarea data-behavior={behavior}>
      {behavior === "input" && (
        <input
          type={isNumericInput ? "tel" : ""}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
        />
      )}
      {behavior === "textarea" && (
        <>
          <textarea placeholder={placeholder} onChange={e => onChange(e.target.value)} value={value} />
          {minChar && value?.trim().length < minChar && (
            <Limit
              variants={bottomToTopItem}
              style={{
                color: value.length > 0 && value.length < minChar ? "#e09999" : "revert",
              }}
            >
              {value?.trim().length} / {minChar}
            </Limit>
          )}
          {maxChar && (
            <MaxLimit variants={bottomToTopItem}>
              {value?.length}/{maxChar}
            </MaxLimit>
          )}
        </>
      )}
      {withHelpIcon && (
        <Help
          whileTap={{
            scale: 0.95,
          }}
          onClick={onHelpRequested}
        >
          <FiHelpCircle size={18} />
        </Help>
      )}
    </InputTextarea>
  )
}

export { Input }
