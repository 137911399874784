import { motion } from "framer-motion"
import React, { ReactChild } from "react"
import "./styles.scss"

interface ButtonBaseProps {
  primary?: boolean
  // eslint-disable-next-line no-undef
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  backgroundColor?: string
  large?: boolean
  disabled?: boolean
}

interface ButtonProps extends ButtonBaseProps {
  text: string
}

interface SmallButtonProps extends ButtonBaseProps {
  icon: ReactChild
  color?: string
}

interface ButtonWithIconProps extends ButtonProps, SmallButtonProps {
  reverse?: boolean
}

const Button: React.FC<ButtonProps> = ({ text, onClick, primary = true, backgroundColor, large }) => {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      className="cta"
      onClick={onClick}
      data-primary={primary}
      style={{ backgroundColor }}
      data-large={large}
    >
      <div className="label">{text}</div>
    </motion.div>
  )
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  text,
  onClick,
  primary = true,
  icon,
  reverse,
  large,
  disabled = false,
}) => {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      className={`cta with-icon ${disabled && "disabled"}`}
      onClick={event => {
        if (!disabled) {
          onClick(event)
        }
      }}
      data-primary={primary && !disabled}
      data-large={large}
      style={{ flexDirection: reverse ? "row-reverse" : "row" }}
    >
      <div className="label">{text}</div>
      {icon && <div className="icon">{icon}</div>}
    </motion.div>
  )
}

const IconButton: React.FC<SmallButtonProps> = ({ onClick, icon, primary = true, backgroundColor, color }) => {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      className="cta small"
      onClick={onClick}
      data-primary={primary}
      style={{ backgroundColor, color }}
    >
      <div className="icon">{icon}</div>
    </motion.div>
  )
}

const RoundButton: React.FC<SmallButtonProps> = ({ onClick, icon, primary = true, backgroundColor, color }) => {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.1 }}
      className="cta small round"
      onClick={onClick}
      data-primary={primary}
      style={{ backgroundColor, color }}
    >
      <div className="icon">{icon}</div>
    </motion.div>
  )
}

export { Button, ButtonWithIcon, IconButton, RoundButton }
