import React, { ReactElement } from "react"
import { bottomToTopItem } from "modules/shared/animations/variants"
import { FormProps } from "types/rating.types"
import { colissimoFormData } from "modules/shared/datas/RatingDatas"
import {
  Message,
  MessageSpacing,
  QuestionContainer,
  QuestionContainerV2,
  QuestionItemText,
  QuestionValues,
} from "modules/rating/screens/Rating.styled"

const HeardAboutPickme = ({ ratingState, handleRatingUpdate, getAnimateProperties }: FormProps): ReactElement => {
  return (
    <>
      <MessageSpacing>
        <Message>{colissimoFormData.q17.title}</Message>
      </MessageSpacing>

      <QuestionContainerV2>
        <QuestionContainer>
          <QuestionValues variants={bottomToTopItem}>
            {colissimoFormData.q17.labels.map(item => (
              <QuestionItemText
                onClick={() => handleRatingUpdate(ratingState.q17, item.value)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item.value}
                animate={getAnimateProperties(ratingState.q17, item.value)}
              >
                {item.label}
              </QuestionItemText>
            ))}
          </QuestionValues>
        </QuestionContainer>
      </QuestionContainerV2>
    </>
  )
}

export default HeardAboutPickme
