import styled from "styled-components"
import { nobelGrey } from "utils/color"
import { Col } from "reactstrap"

interface LineColProps {
  width?: string
  maxWidth?: string
}

export const LineCol = styled(Col)<LineColProps>`
  padding-right: 0.5em;
  padding-left: 0.5em;
  width: ${({ width }: LineColProps) => width ?? "unset"};
  max-width: ${({ maxWidth }: LineColProps) => maxWidth ?? "unset"};
  @media (max-width: 700px) {
    max-width: 100%;
  }
`

export const LineElementInfo = styled.div`
  display: flex;
  row-gap: 1px;
  flex-direction: column;
`

export const LineElementLabel = styled.p`
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  color: ${nobelGrey};
`

export const LineElementContent = styled.div``
