import React, { ReactElement } from "react"
import { silverGrey, yellow } from "utils/color"

interface SmallStarProps {
  index: number
  rating: number | undefined
  hoverRating: number | undefined
}

export const SmallStar = ({ index, rating, hoverRating }: SmallStarProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 57 54"
      fill={index < rating || index < hoverRating ? yellow : silverGrey}
    >
      <path
        fill={index < rating || index < hoverRating ? yellow : "none"}
        d="M27.8576 1.09787C28.0068 0.63623 28.6599 0.636231 28.8091 1.09787L29.2849 0.944104L28.8091 1.09787L34.6685 19.2273C34.8686 19.8464 35.4451 20.266 36.0958 20.266H55.0686C55.5523 20.266 55.7541 20.8844 55.3635 21.1697L40.0051 32.3876C39.4822 32.7696 39.2634 33.444 39.4625 34.0602L45.3268 52.2044C45.4758 52.6656 44.9474 53.0478 44.5561 52.762L29.2181 41.559C28.691 41.174 27.9756 41.174 27.4486 41.559L12.1106 52.762C11.7192 53.0478 11.1909 52.6656 11.3399 52.2044L17.2041 34.0602C17.4033 33.444 17.1845 32.7696 16.6615 32.3876L1.30316 21.1697C0.912545 20.8844 1.11436 20.266 1.59808 20.266H20.5708C21.2215 20.266 21.798 19.8464 21.9981 19.2273L27.8576 1.09787Z"
        stroke={index < rating || index < hoverRating ? "none" : silverGrey}
      />
    </svg>
  )
}
