import React, { ReactElement } from "react"
import { fontSizes } from "utils/devices"

interface EmojiProps {
  label: string
  children: React.ReactNode
  size?: "" | "small" | "regular" | "extra-big"
  style?: object
}

export const Emoji = ({ label, children, size = "", style }: EmojiProps): ReactElement => {
  let sizeStyle = {}
  switch (size) {
    case "small":
      sizeStyle = { fontSize: fontSizes.desktop.small }
      break
    case "extra-big":
      sizeStyle = { fontSize: fontSizes.desktop.extraBig }
      break
    default:
      sizeStyle = { fontSize: fontSizes.desktop.regular }
  }
  return (
    <span role="img" aria-label={label} style={{ ...style, ...sizeStyle }}>
      {children}
    </span>
  )
}
