import styled from "styled-components"

import { WhiteCard } from "modules/shared/components/WhiteCard.styled"
import { corail, lightCorail } from "utils/color"
import { devices, fontSizes } from "utils/devices"

export const HeaderContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding-right: 10%;
  padding-left: 10%;

  @media ${devices.mobile} {
    height: 50px;
    margin-right: 10px;
    padding-right: 0;
    padding-left: 0;
  }
`

export const PickmeLinkContainer = styled.a`
  display: flex;
  align-items: center;
  @media ${devices.mobile} {
    // maw-width setting is needed to display next element properly on mobile device.
    max-width: 30%;
  }
`

export const PickmeLogoContainer = styled.img`
  height: 70%;

  @media ${devices.mobile} {
    height: 100%;
  }
`

export const ZendeskLinkContainer = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${corail};
  font-weight: 600;
  font-size: ${fontSizes.desktop.regular};

  @media ${devices.mobile} {
    font-size: ${fontSizes.mobile.regular};
  }
`

export const Container = styled.div`
  height: 100%;
  background-color: ${lightCorail};
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 30px;

  @media ${devices.mobile} {
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const ErrorContainer = styled.div`
  height: 100%;
  background-color: ${lightCorail};
  display: flex;
  justify-content: center;

  @media ${devices.mobile} {
    align-items: center;
  }
`

export const ErrorMessageContainer = styled(WhiteCard)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 700px;
  height: 300px;
  margin-top: 100px;

  @media ${devices.mobile} {
    margin: 12px;
  }
`

export const ErrorMessage = styled.p`
  font-size: ${fontSizes.desktop.medium};
  text-align: center;
`

export const ErrorPickmeLogoContainer = styled.img`
  width: 230px;
`
