/* eslint-disable no-undef */
import styled from "styled-components"
import { Link } from "react-router-dom"

import { WhiteCard } from "modules/shared/components/WhiteCard.styled"
import { devices, fontSizes } from "utils/devices"
import { green, white } from "utils/color"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  height: ${window.innerHeight - 70 - 30}px;

  @media ${devices.mobile} {
    width: 100%;
    height: ${window.innerHeight - 50 - 12 - 75}px;
  }
`

export const ChatContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const DeliveredParcelContainer = styled.div`
  width: 410px;
  margin-bottom: 60px;

  @media ${devices.mobile} {
    width: 350px;
    margin-bottom: 30px;
  }
`

export const NoteContainer = styled(WhiteCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: ${fontSizes.desktop.regular};
`

export const ThankYouNoteContainer = styled.p`
  margin-top: 35px;
  text-align: center;
`

export const TellUsMoreLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 30px;
  background-color: ${green};
  color: ${white};
  font-size: ${fontSizes.desktop.regular};
  font-weight: bold;
  text-decoration: none;
  margin-top: 20px;
`
