import React, { Fragment, ReactElement } from "react"
import cx from "classnames"
import { useSelector } from "react-redux"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { UserBox } from "modules/Carriers/components/AppStructure/AppHeader/components/UserBox"
import { ReduxState } from "types/reduxState.types"

interface AppHeaderProps {
  logout: () => void
}

export const AppHeader = ({ logout }: AppHeaderProps): ReactElement => {
  const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = useSelector(
    (state: ReduxState) => state.ThemeOptions,
  )

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-header", headerBackgroundColor, { "header-shadow": enableHeaderShadow })}
        transitionName="HeaderAnimation"
        transitionAppear
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className={cx("app-header__content", { "header-mobile-open": enableMobileMenuSmall })}>
          <div className="app-header-right">
            <UserBox logout={logout} />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
