import { useEffect, useState } from "react"
import * as companyUserQueries from "services/graphql/queries/user.queries"
import { useAuthToken } from "hooks/useAuthToken"
import * as jwt from "jsonwebtoken"
import { useHistory } from "react-router-dom"
import { useAppApolloClient } from "services/graphql/apollo-client"

// import BugTracking from "@pickme/utils/bugTracking"
// import Analytics from "@pickme/utils/analytics"

export type UseCheckUserType = {
  logged: boolean
  user: any
  ready: boolean
  logout: () => void
}

export const useCheckUser = (started: boolean): UseCheckUserType => {
  const [logged, setLogged] = useState(false)
  const history = useHistory()
  const [user, setUser] = useState(null)
  const [ready, setReady] = useState(false)
  const apolloClient = useAppApolloClient()
  const { authToken, removeAuthToken } = useAuthToken()

  const resetSentryAndSegment = () => {
    // BugTracking.reset()
    // Analytics.reset()
  }

  const logout = async () => {
    await apolloClient.clearStore() // we remove all information in the store
    removeAuthToken() //we clear the authToken
    history.push("/carriers/login")
  }

  useEffect(() => {
    const checkUser = async () => {
      // BugTracking.init()
      // Analytics.init()

      if (authToken !== null) {
        try {
          const decodedToken = jwt.decode(authToken, { complete: true })
          const dateNow = new Date()

          const payload: jwt.JwtPayload | string = decodedToken.payload

          if (!payload || typeof payload === "string" || !payload.expiredAt) {
            throw Error("Invalid token format")
          }

          if (payload.expiredAt < dateNow.getTime() / 1000) {
            throw Error("Token expired")
          }

          const response = await apolloClient.query({
            query: companyUserQueries.getCurrentCompanyUser,
          })

          if (response?.data?.getCurrentCompanyUser) {
            setLogged(true)
            setUser(response.data.getCurrentCompanyUser)
          }
        } catch (e) {
          resetSentryAndSegment()
          removeAuthToken()
          setLogged(false)
          setReady(true)
        }
      } else {
        resetSentryAndSegment()
        removeAuthToken()
        setLogged(false)
        setReady(true)
      }
      setReady(true)
    }
    started && checkUser()
  }, [started])
  return { logged, user, ready, logout }
}
