import styled from "styled-components"
import { white } from "utils/color"
import { darkBlue } from "theme/colors"

export const BannerWrapper = styled.div`
  margin-top: 90px;
  padding: 0 40px;
  width: 100%;
`

export const Banner = styled.div`
  position: relative;
  background-color: ${darkBlue};
  opacity: 0.95;
  color: ${white};
  width: 100%;
  max-width: 700px;
  padding: 35px 61px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 12px 19px 0 rgba(51, 204, 153, 0.09);

  @media (max-width: 700px) {
    padding: 35px 40px;
  }

  @media (max-width: 480px) {
    padding: 20px 20px;
  }
`

export const BannerHeader = styled.p`
  font-size: 20px;
  text-align: center;
  margin-bottom: 17px;
  padding: 0 85px;

  @media (max-width: 700px) {
    padding: 0 30px;
    font-size: 17px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`

export const BannerText = styled.p`
  font-size: 16px;
  margin-bottom: 0;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

export const ImageTopLeft = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
`

export const ImageBottomRight = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.3;
`
