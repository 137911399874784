import styled from "styled-components"

export const Subtitle = styled.p`
  font-size: 14px;
`

export const ParcelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
