import styled from "styled-components"
import { corail, white } from "utils/color"

export const ButtonContainer = styled.button`
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 10px;
  border: none;
  background-color: ${corail};
  color: ${white};
`

export const Wrapper = styled.div`
  &:hover ${ButtonContainer} {
    background-color: ${corail};
  }
`
