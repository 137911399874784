export const themeSecondary = "#00d9cd"

export const backgroundGrey = "#efefef"

export const davyGrey = "#535353"

export const darkBlue = "#000424f2"

export const lightGrey = "#b4b4b4"

export const inputColor = "#f5f6ff"
