import React from "react"
import { bottomToTopItem } from "modules/shared/animations/variants"
import { FormProps } from "types/rating.types"
import { colissimoFormData } from "modules/shared/datas/RatingDatas"
import {
  Message,
  QuestionContainer,
  QuestionTitleThemeColorBold,
  RatingContainer,
  RatingItem,
  RatingValues,
} from "modules/rating/screens/Rating.styled"

const SatisfactionLevel = ({ ratingState, handleRatingUpdate, getAnimateProperties }: FormProps) => {
  return (
    <QuestionContainer>
      <Message>
        Quel a été votre <b>niveau de satisfaction</b> sur les points suivants ?
      </Message>
      <RatingContainer>
        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q6.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q6.labels.map(item => (
              <RatingItem
                onClick={() => handleRatingUpdate(ratingState.q6, item)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item}
                animate={getAnimateProperties(ratingState.q6, item)}
              >
                {item}
              </RatingItem>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q7.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q7.labels.map(item => (
              <RatingItem
                onClick={() => handleRatingUpdate(ratingState.q7, item)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item}
                animate={getAnimateProperties(ratingState.q7, item)}
              >
                {item}
              </RatingItem>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q8.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q8.labels.map(item => (
              <RatingItem
                onClick={() => handleRatingUpdate(ratingState.q8, item)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item}
                animate={getAnimateProperties(ratingState.q8, item)}
              >
                {item}
              </RatingItem>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q9.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q9.labels.map(item => (
              <RatingItem
                onClick={() => handleRatingUpdate(ratingState.q9, item)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item}
                animate={getAnimateProperties(ratingState.q9, item)}
              >
                {item}
              </RatingItem>
            ))}
          </RatingValues>
        </QuestionContainer>

        <QuestionContainer>
          <QuestionTitleThemeColorBold>{colissimoFormData.q10.title}</QuestionTitleThemeColorBold>
          <RatingValues variants={bottomToTopItem}>
            {colissimoFormData.q10.labels.map(item => (
              <RatingItem
                onClick={() => handleRatingUpdate(ratingState.q10, item)}
                whileTap={{
                  scale: 0.9,
                }}
                key={item}
                animate={getAnimateProperties(ratingState.q10, item)}
              >
                {item}
              </RatingItem>
            ))}
          </RatingValues>
        </QuestionContainer>
      </RatingContainer>
    </QuestionContainer>
  )
}

export default SatisfactionLevel
