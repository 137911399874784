import { gql } from "apollo-boost"

interface RecipientInput {
  firstName: string
  lastName?: string
  email?: string
  phone?: string
}

export interface SaveRecipientCoordinatesFromCarrierVariables {
  orderId: string
  recipient: RecipientInput
}

export interface SendNoRecipientDetailsAvailableVariables {
  parcelId: string
}

export const saveRecipientCoordinatesFromCarrier = gql`
  mutation saveRecipientCoordinatesFromCarrier($orderId: String!, $recipient: RecipientInput!) {
    saveRecipientCoordinatesFromCarrier(orderId: $orderId, recipient: $recipient)
  }
`

export const noRecipientDetailsAvailable = gql`
  mutation noRecipientDetailsAvailable($parcelId: String!) {
    noRecipientDetailsAvailable(parcelId: $parcelId)
  }
`
