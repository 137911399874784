import styled from "styled-components"

interface LoadingContainerProps {
  paddingTop: string
  paddingBottom: string
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: ${({ paddingTop }: LoadingContainerProps) => paddingTop};
  padding-bottom: ${({ paddingBottom }: LoadingContainerProps) => paddingBottom}; ;
`
