import { gql } from "apollo-boost"
import { Parcel } from "types/parcel.types"

export interface GetUnannouncedParcelsWithoutCoordinatesData {
  getUnannouncedParcelsWithoutCoordinates: Parcel[]
}

export const getUnannouncedParcelsWithoutCoordinates = gql`
  query getUnannouncedParcelsWithoutCoordinates {
    getUnannouncedParcelsWithoutCoordinates {
      id
      parcelNo
      createdAt
      order {
        id
        recipient {
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`
