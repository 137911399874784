import React, { ReactElement } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

import { OrderInfos } from "modules/DestiChat/OrderInfos"
import { Chat } from "modules/DestiChat/Chat"
import { Loader } from "modules/shared/components/Loader/Loader"
import { Container } from "modules/DestiChat/DestiChat.styled"
import { Error } from "modules/DestiChat/Error"
import { Header } from "modules/DestiChat/Header"
import FullScreenContainer from "modules/shared/components/FullScreenContainer/FullScreenContainer"
import { getOrderChatData } from "services/graphql/queries/orders.queries"
import { useSaveParcelEventMutation } from "hooks/saveParcelEvent"
import { ChatAccessRights, OrderChatData } from "types/chat.types"
import { pickmeWebSite } from "utils/links"
import { redirectToExternalUrl } from "utils/redirectToExternalUrl"

export const DestiChat = (): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>()

  if (!orderId) redirectToExternalUrl(pickmeWebSite)

  const { data, loading, error } = useQuery<OrderChatData>(getOrderChatData, {
    variables: {
      orderId,
    },
  })

  const [saveParcelEvent] = useSaveParcelEventMutation()

  if (error || data?.getOrderChatData?.chatAccessRights === ChatAccessRights.NO_ACCESS) {
    return (
      <FullScreenContainer>
        <Error />
      </FullScreenContainer>
    )
  }

  if (loading) {
    return (
      <FullScreenContainer>
        <Loader />
      </FullScreenContainer>
    )
  }

  return (
    <FullScreenContainer>
      <Header handleSaveParcelEvent={saveParcelEvent} parcelId={data.getOrderChatData.parcelId} />
      <Container>
        <OrderInfos order={data.getOrderChatData} />
        <Chat order={data.getOrderChatData} handleSaveParcelEvent={saveParcelEvent} />
      </Container>
    </FullScreenContainer>
  )
}
