import React, { ReactElement, useState } from "react"
import {
  Star,
  StarContainer,
  StarItem,
  StarRatingContainer,
  StarTextItem,
  Text,
} from "modules/rating/components/StarRating/StarRating.styled"
import { SmallStar } from "resources/svg/SmallStar"
import { LargeStar } from "resources/svg/LargeStar"

interface StarRatingProps {
  rating: number | undefined
  setRating: (rating: number) => void
  isKeeperRate: boolean
}

const StarRating = ({ rating, setRating, isKeeperRate }: StarRatingProps): ReactElement => {
  const [hoverRating, setHoverRating] = useState(0)

  const handleHoverRating = (rating: number) => {
    if (isKeeperRate) return
    setHoverRating(rating)
  }

  const displayStar = (index: number): ReactElement => {
    return isKeeperRate ? (
      <LargeStar rating={rating} hoverRating={hoverRating} index={index} />
    ) : (
      <SmallStar rating={rating} hoverRating={hoverRating} index={index} />
    )
  }

  const getStarText = (value: number): ReactElement | string => {
    switch (value) {
      case 1:
        return (
          <>
            {isKeeperRate ? (
              <>
                <Text>Très mécontent</Text>
              </>
            ) : (
              <>
                <Text>Très</Text>
                <Text>mécontent</Text>
              </>
            )}
          </>
        )
      case 2:
        return "Insatisfait"
      case 3:
        return "Moyen"
      case 4:
        return "Bon"
      case 5:
        return "Excellent"
    }
  }

  return (
    <StarRatingContainer>
      <StarContainer isKeeperRate={isKeeperRate} onMouseLeave={() => handleHoverRating(0)}>
        {[...Array(5)].map((_, i) => (
          <StarItem isKeeperRate={isKeeperRate} onClick={() => setRating(i + 1)} key={i}>
            <Star isKeeperRate={isKeeperRate} onMouseEnter={() => handleHoverRating(i + 1)} isHovered={i < hoverRating}>
              {displayStar(i)}
            </Star>
            <StarTextItem isKeeperRate={isKeeperRate} key={i}>
              {getStarText(i + 1)}
            </StarTextItem>
          </StarItem>
        ))}
      </StarContainer>
    </StarRatingContainer>
  )
}

export default StarRating
