import React, { ReactElement, useEffect, useRef, useState } from "react"
import { AnimateProperties, AnswerInput, RatingState } from "types/rating.types"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import {
  ActionContainer,
  CtaBlocBottom,
  CtaTipContainer,
  LoaderContainer,
  MessageMotion,
  PeerRating,
  RatingContainer,
  SkipButton,
  StepContainer,
  TipItem,
  TipTitle,
  TipValues,
} from "modules/rating/screens/Rating.styled"
import { topToBottom } from "modules/shared/animations/variants"
import { green, grey, white } from "utils/color"
import { Order } from "models/order.model"
import { standardFormData } from "modules/shared/datas/RatingDatas"
import { initialTipState } from "modules/rating/datas/Tip/TipDatas"
import * as ratingQueries from "services/graphql/queries/rating.queries"
import { useMutation } from "@apollo/client"
import { Loader } from "semantic-ui-react"
import { useLocation } from "react-router-dom"

interface TipProps {
  order: Order
  onTipUpdated: (answers: AnswerInput[], isSkip?: boolean) => void
}

export const Tip = ({ order, onTipUpdated }: TipProps): ReactElement => {
  const { pathname } = useLocation()

  const [tipState, setTipState] = useState<RatingState>(initialTipState)
  const [isChangingPath, setIsChangingPath] = useState(false)

  const [prepareTipsPaymentLinkMutation] = useMutation<
    ratingQueries.PrepareTipsPaymentLinkData,
    ratingQueries.PrepareTipsPaymentLinkVariables
  >(ratingQueries.prepareTipsPaymentLink)

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  function getButtonBackgroundColor(selected: boolean): string {
    return selected ? green : white
  }

  function getAnimateProperties(question: AnswerInput, item: number): AnimateProperties {
    return {
      background: getButtonBackgroundColor(question.note === item),
      color: question.note === item ? white : grey,
    }
  }

  const handleTipUpdate = async (question: AnswerInput, item: string | number, tips: number) => {
    setIsChangingPath(true)

    setTipState((prevState: RatingState) => ({
      ...prevState,
      [`q${question.order}`]: { ...question, note: item, tips },
    }))

    const data = await prepareTipsPaymentLinkMutation({ variables: { tipsInput: { orderId: order.id, amount: tips } } })
    const url = data?.data?.prepareTipsPaymentLink?.url
    if (url) {
      onSend(item, tips)
      setTimeout(() => window.location.replace(url), 5000)
    } else {
      onSend(item, tips, true)
    }
  }

  const onSend = (note: string | number, tips: number, isSkip?: boolean) => {
    onTipUpdated([{ ...tipState.q4, note, tips }], isSkip)
    isDataSent.current = true
  }

  /** If the user chooses to skip the tip, the following object will be sent: { order: 4, type: AnswerType.CHOICE, note: "" }  */
  const onSkip = () => {
    onTipUpdated([{ ...initialTipState.q4, note: "" }], true)
    isDataSent.current = true
  }

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current) {
        onSkip()
      }
    }
  }, [])

  return (
    <PeerChallengeFeedbackContainer>
      <StepContainer>
        <ActionContainer>
          <>
            <div>
              <PeerRating>
                <MessageMotion variants={topToBottom}>
                  <TipTitle>
                    Vous semblez ravi(e) de votre expérience avec <b>{order?.address?.keeper?.firstName}</b> !<br />
                    Voulez-vous lui donner un pourboire pour le/la remercier d&apos;avoir pris soin de votre colis ?
                  </TipTitle>
                </MessageMotion>
                <RatingContainer>
                  <TipValues>
                    {standardFormData.q4.labels.map((item, index) => (
                      <TipItem
                        selected={tipState.q4.note === index + 1}
                        onClick={() => handleTipUpdate(tipState.q4, index + 1, item.value)}
                        whileTap={{
                          scale: 0.9,
                        }}
                        key={item.value}
                        animate={getAnimateProperties(tipState.q4, index + 1)}
                      >
                        {item.label}
                      </TipItem>
                    ))}
                  </TipValues>
                </RatingContainer>
              </PeerRating>
            </div>
          </>
          <CtaBlocBottom marginTop={70}>
            <CtaTipContainer>
              {isChangingPath && pathname.includes("/tip") ? (
                <LoaderContainer>
                  <Loader inline active key={10} size={"small"} />
                </LoaderContainer>
              ) : (
                <SkipButton onClick={() => onSkip()}>Pas cette fois</SkipButton>
              )}
            </CtaTipContainer>
          </CtaBlocBottom>
        </ActionContainer>
      </StepContainer>
    </PeerChallengeFeedbackContainer>
  )
}
