import { gql } from "@apollo/client"

export const getCurrentCompanyUser = gql`
  query getCurrentCompanyUser {
    getCurrentCompanyUser {
      id
      email
    }
  }
`
