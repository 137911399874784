import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "styles/index.scss"
import * as serviceWorker from "./serviceWorker"
import ReactGA from "react-ga4"

const tagGA4 = process.env.REACT_APP_GA4

if (process.env.REACT_APP_STAGE !== "development" && tagGA4 !== undefined) {
  //Initialize GA4
  ReactGA.initialize(tagGA4)
  // eslint-disable-next-line no-undef
  ReactGA.send({ hitType: "pageview", page: window.location.pathname })
}

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
