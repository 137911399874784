import React, { ReactElement } from "react"
import { CarriersName } from "types/carriers.types"

const OrderAlreadyRated: React.FC<{ deliveryCarrierName: string }> = ({ deliveryCarrierName }) => {
  function getTitle(): string | ReactElement {
    switch (deliveryCarrierName) {
      case CarriersName.COLISSIMO:
        return "Le retour pour cette livraison a déjà été fait !"

      default:
        return "Le retour pour cette commande a déjà été fait !"
    }
  }

  return (
    <div className="step-container">
      <div className="action-container">
        <div className="message">
          <div className="message">
            <div className="title">
              Oups{" "}
              <span role="img" aria-label="confused">
                🙁
              </span>
              <div className="content">{getTitle()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderAlreadyRated
