import React from "react"
import { Carriers } from "modules/Carriers/Carriers"

const routes = [
  {
    name: "",
    inSidebar: true,
    authorizedRoles: [],
    environments: ["development", "staging", "production"],
    elements: [
      {
        path: "/carriers/parcels-without-contact",
        icon: <i className="metismenu-icon font-style-normal">📦</i>,
        exact: true,
        component: Carriers,
        inSidebar: true,
        label: "Colis sans coordonnées",
        authorizedRoles: [],
        children: [],
      },
    ],
  },
]

export default routes
