import styled from "styled-components"
import { Modal } from "semantic-ui-react"

export const CancelModalContent = styled(Modal.Content)`
  display: flex;
  justify-content: center;
  text-align: center;
`

export const ModalLoader = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 10;
`
