import React, { Fragment, ReactElement, useState } from "react"
import { useLocation } from "react-router-dom"

import { getSidebarConfig } from "config/routing"
import { Icon } from "semantic-ui-react"
import { NavSoloItem } from "modules/Carriers/components/AppStructure/AppNav/NavSoloItem"
import { NavMultipleItem } from "modules/Carriers/components/AppStructure/AppNav/NavMultipleItem"
import { DoubleArrow } from "modules/Carriers/components/AppStructure/AppNav/AppNav.styled"

export interface NavProps {
  toggleSidebar: (enable: boolean) => void
  enableClosedSidebar: boolean
}

export const Nav = ({ toggleSidebar, enableClosedSidebar }: NavProps): ReactElement => {
  const [openedMenu, setOpenedMenu] = useState(false)
  const location = useLocation()

  const translateMenu = routes => {
    return routes.map(category => ({
      ...category,
      name: category.name,
      elements: category.elements.map(elt => ({
        ...elt,
        label: elt.label,
        children: elt.children.map(child => ({
          ...child,
          label: child.label,
        })),
      })),
    }))
  }

  const handleOpenedMenu = _name => {
    if (openedMenu !== _name) setOpenedMenu(_name)
    else setOpenedMenu(null)
  }

  const config = translateMenu(getSidebarConfig())

  const categories = config

  return (
    <Fragment>
      {categories
        .filter(elt => elt.environments.includes(process.env.REACT_APP_STAGE))
        .map(
          category =>
            category.inSidebar && (
              <React.Fragment key={`fragment-${category.name}`}>
                <h5 className="app-sidebar__heading">{category.name}</h5>
                <div className="metismenu vertical-nav-menu">
                  <ul className="metismenu-container">
                    {category.elements.map(item => (
                      <React.Fragment key={`fragment-item-${item.path}`}>
                        {item.inSidebar && item.children.length === 0 && (
                          <NavSoloItem
                            content={item}
                            active={location?.pathname === item.path}
                            key={`navSoloItem-${item.path}`}
                          />
                        )}
                        {item.inSidebar && item.children.length > 0 && (
                          <NavMultipleItem
                            item={item}
                            key={`navMultipleItem-${item.path}`}
                            pathname={location?.pathname}
                            opened={false /* openedMenu === item.name */}
                            onClick={() => handleOpenedMenu(item.name)}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </React.Fragment>
            ),
        )}

      <DoubleArrow>
        <Icon name={enableClosedSidebar ? `unlock` : `lock`} onClick={() => toggleSidebar(!enableClosedSidebar)} />
      </DoubleArrow>
    </Fragment>
  )
}
