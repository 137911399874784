import React, { Fragment, ReactElement } from "react"
import { DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from "reactstrap"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface UserBoxProps {
  logout: () => void
}

export const UserBox = ({ logout }: UserBoxProps): ReactElement => {
  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left header-user-info">
              <div className="widget-heading"></div>
            </div>
            <div className="widget-content-left ml-3">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0 userBoxButton">
                  <FontAwesomeIcon className="opacity-8" icon={faAngleDown} />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem onClick={() => logout()}>
                      <NavLink>Se déconnecter</NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
