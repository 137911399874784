import styled from "styled-components"
import { motion } from "framer-motion"
import { boxShadow1 } from "theme/boxShadows"
import { black, green, grey, white } from "utils/color"
import { davyGrey, lightGrey } from "theme/colors"

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const PeerRating = styled.div`
  width: 100%;
  text-align: center;
`

export const QuestionBlock = styled.div`
  padding-bottom: 10px;
`

export const RatingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 700px) {
    padding-top: 20px;
    margin-bottom: 15px;
  }
`

export const RatingValues = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  transition: font-size 1s;
`

export const TipValues = styled(motion.div)`
  display: flex;
  padding: 0 30px 0 30px;
  justify-content: space-between;
  align-items: center;
  font-size: 2em;
  transition: font-size 1s;

  @media (max-width: 550px) {
    padding: 0 10px 0 10px;
  }
`

export const RatingItem = styled(motion.div)`
  display: flex;
  cursor: pointer;
  margin: auto 0.2em;
  width: 2.5em;
  height: 2.5em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  border-radius: 0.3em;

  @media (max-width: 700px) {
    margin: 0.45em 0.2em;
  }
`

export const TipItem = styled(motion.div)<{ selected?: boolean }>`
  display: flex;
  cursor: pointer;
  margin: auto 0.2em;
  width: 4.6em;
  height: 3.2em;
  font-size: 0.8em;
  align-items: center;
  justify-content: center;
  border: ${({ selected }: { selected: false }) => (selected ? "none" : `1px solid ${black}`)};
  border-radius: 0.3em;

  @media (max-width: 700px) {
    margin: 0.45em 0.2em;
  }

  @media (max-width: 550px) {
    font-size: 0.7em;
  }

  @media (max-width: 400px) {
    width: 4em;
    height: 3em;
  }
`

export const RatingItemText = styled(motion.div)`
  display: flex;
  cursor: pointer;
  margin: 0.45em 0.2em;
  height: 2.5em;
  font-size: 0.45em;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  border-radius: 0.3em;
  padding: 0 1em;

  @media (max-width: 700px) {
    margin: 0.45em 0.2em;
  }
`

export const QuestionContainer = styled.div`
  margin-bottom: 1.5em;

  @media (max-width: 700px) {
    margin-bottom: 1em;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const QuestionTitle = styled.p<{ isKeeperRate?: boolean }>`
  font-size: ${({ isKeeperRate }: { isKeeperRate: boolean }) => (isKeeperRate ? "1.75em" : "0.94em")};
  margin: ${({ isKeeperRate }: { isKeeperRate: boolean }) => (isKeeperRate ? "0 auto 2.4em auto" : "0.8em")};
  max-width: ${({ isKeeperRate }: { isKeeperRate: boolean }) => (isKeeperRate ? "88%" : "none")};

  @media (max-width: 700px) {
    margin: ${({ isKeeperRate }: { isKeeperRate: boolean }) => (isKeeperRate ? "0 auto 1.9em auto" : "0.8em")};
    font-size: ${({ isKeeperRate }: { isKeeperRate: boolean }) => (isKeeperRate ? "1.4em" : "0.94em")};
  }

  @media (max-width: 650px) {
    font-size: ${({ isKeeperRate }: { isKeeperRate: boolean }) => (isKeeperRate ? "1.4em" : "0.94em")};
  }
`

export const QuestionTitleThemeColorBold = styled(QuestionTitle)`
  color: ${green};
  font-weight: 600;
`

export const QuestionContainerV2 = styled.div`
  margin-bottom: 2em;

  @media (max-width: 700px) {
    margin-bottom: 1.5em;
  }
`

export const QuestionContainerV2Spacing = styled.div`
  margin-top: 2.5em;

  @media (max-width: 700px) {
    margin-top: 1.5em;
  }
`

export const QuestionValues = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  transition: font-size 1s;
  margin-top: 0.35em;

  &:has(> :nth-child(3)) {
    flex-direction: column;
  }
`

export const QuestionItemText = styled(motion.div)`
  display: flex;
  cursor: pointer;
  height: 2.5em;
  font-size: 0.45em;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  border-radius: 0.3em;
  padding: 0 0.5em;
  margin: auto 0.25em 0.35em;

  @media (max-width: 480px) {
    margin: 0.5em 0.2em;
  }
`

// ----------------------------------------------------

export const ActionContainer = styled.div<{ wider?: boolean; marginTop?: number }>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "90px")};
  width: 100%;
  max-width: ${({ wider }) => (wider ? "780px" : "700px")};
  padding: ${({ wider }) => (wider ? "40px 0" : "40px")};
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${white};
  border-radius: 13px;
  box-shadow: ${boxShadow1};
  position: relative;
  color: ${grey};
  margin-bottom: 80px;
`

export const ActionContainerQuestionSpacing = styled.div`
  padding: 40px 75px;

  @media (max-width: 700px) {
    display: block;
    padding: 40px 60px;
  }
`

export const ActionContainerSurveySpacing = styled.div`
  padding: 40px;

  @media (max-width: 700px) {
    display: block;
    padding: 40px 20px;
  }
`

export const Message = styled.p`
  font-size: 1em;
  line-height: 1.35em;
  color: ${grey};
  margin: 0 auto;

  .title {
    text-align: center;
  }

  .content {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.8em;
    text-align: center;
    line-height: 1.5em;
  }

  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

export const Subquestion = styled.p`
  margin: 0;
`

export const MessageGray = styled.p`
  color: ${lightGrey};
  font-size: 0.7em;
  font-style: italic;
  margin-bottom: 0;
`

export const MessageMotion = styled(motion.div)`
  font-size: 1em;
  line-height: 1.35em;
  color: ${grey};
  margin: 0 auto;

  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ${Subquestion} {
    padding-bottom: 0;
  }

  ${MessageGray} {
    padding-top: 0;
  }
`

export const MessageSpacing = styled.div`
  padding-top: 20px;
`

export const MessageWidth = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 700px) {
    width: 85%;
  }
`

export const Title = styled.div`
  font-size: 1.2em;
`

export const FeedbackInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    text-align: center;
  }
`

export const Feedback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const FeedbackEditor = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 40px;

  @media (max-width: 700px) {
    margin-bottom: 30px;
  }
`

export const CtaBloc = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 700px) {
    margin-top: 15px;
  }
`

export const SpecialSumBlock = styled.div`
  padding-bottom: 30px;
`

export const CtaBlocBottom = styled(CtaBloc)<{ marginTop?: number }>`
  width: 100%;
  bottom: 40px;
  left: 40px;
  right: 40px;
  margin-top: ${({ marginTop }: { marginTop: number }) => (marginTop ? `${marginTop}px` : "30px")};
`

export const CtaContainer = styled(motion.div)`
  min-width: 300px;

  @media (max-width: 350px) {
    min-width: 250px;
  }
`

export const TitleData = styled.span``

export const BoldText = styled.span`
  font-weight: bold;
`

export const SkipButton = styled.button`
  font-family: "Poppins";
  background-color: transparent;
  font-weight: 600;
  color: ${davyGrey};
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.1em;
  white-space: nowrap;
`

export const CtaTipContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: center;
  padding: 0 65px 0 65px;

  @media (max-width: 550px) {
    padding: 0 10px 0 10px;
  }
`

export const TipTitle = styled.p`
  font-size: 16px;
  color: ${grey};
  line-height: 24px;
`

export const TextWithDefaultStyle = styled.span`
  text-decoration: none;
  font-style: normal;
`

export const KeeperRateSpacing = styled.div`
  padding-bottom: 70px;
`

export const HideContainer = styled.div<{ isVisible: boolean }>`
  position: relative;
  opacity: ${({ isVisible }) => (isVisible ? 100 : 20)};
  transition: all 0.5s ease-out;
  &:before {
    content: "";
    z-index: 100;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: not-allowed !important;
    display: ${({ isVisible }) => (isVisible ? "none" : "block")};
    background-color: ${({ isVisible }) => (isVisible ? "rgb(255, 255, 255, 0)" : "rgb(255, 255, 255, 0.6)")};
  }
`

export const FeedbackQuestionTitle = styled.p`
  font-size: 0.94em;
  margin: 0;
  padding: 0 !important;
`

export const Text = styled.p`
  margin: 0;
`

export const LoaderContainer = styled.div`
  margin-top: -3px;
`
