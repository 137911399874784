import { AnswerInput } from "types/rating.types"

export class Rating {
  orderId: string
  appSessionId: string
  nps: number
  rate: number
  rate_keeper: number
  feedback: string
  feedback_keeper: string
  tipAmount: number
  answers: AnswerInput[]

  constructor(orderId: string) {
    this.orderId = orderId
    this.appSessionId = undefined
    this.feedback = undefined
    this.feedback_keeper = undefined
    this.nps = undefined
    this.rate = undefined
    this.rate_keeper = undefined
    this.tipAmount = undefined
    this.answers = undefined
  }
}
