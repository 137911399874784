import styled from "styled-components"

export const StyledDivBot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const hmbShadowStyles = `
.OverlayContainer {
  background-color: #f2f2f2;
}
.userInputContainer {
  padding-left: 10px;
  padding-right: 10px;
}
.sc-user-input {
  border-radius: 30px !important;
  background-color: #fff !important;
  box-shadow: 3px 0px 20px 5px rgba(197, 197, 197, 0.25);
}
.UserInput_container form .UserInput_input {
  background-color: #fff !important;
}
.sc-user-input--text.UserInput_input::placeholder {
  background-color: #fff !important;
}
`
