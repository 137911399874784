import { useEffect } from "react"

export const useScript = (url, keeperId, parcelNo) => {
  const HMBotKey = process.env.REACT_APP_HMBOT_KEY
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const script = document.createElement("script")

    script.src = url
    script.async = true
    script.id = "HMB"
    script.setAttribute("key", HMBotKey)
    script.setAttribute("target", "divBot")
    script.setAttribute("keeperid", keeperId)
    script.setAttribute("parcelno", parcelNo)

    // eslint-disable-next-line no-undef
    document.body.appendChild(script)

    return () => {
      // eslint-disable-next-line no-undef
      document.body.removeChild(script)
    }
  }, [url, keeperId])
}
