import { gql } from "apollo-boost"

export interface LoginData {
  companyUserLogin: {
    company: {
      id: string
      name: string
    }
    token: {
      accessToken: string
      expiredAt: number
    }
  }
}

export interface LoginVariables {
  email: string
  password: string
}

export const login = gql`
  mutation companyUserLogin($email: String!, $password: String!) {
    companyUserLogin(email: $email, password: $password) {
      token {
        accessToken
        expiredAt
      }
      company {
        id
        name
      }
    }
  }
`
