import React, { Fragment, ReactElement } from "react"
import { useDispatch, useSelector } from "react-redux"
import cx from "classnames"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import PerfectScrollbar from "react-perfect-scrollbar"

import { setEnableClosedSidebar, setEnableMobileMenu } from "reducers/ThemeOptions"

import Logo from "assets/logos/pickme-logo.png"
import { Nav } from "modules/Carriers/components/AppStructure/AppNav/VerticalNavWrapper"
import { ReduxState } from "types/reduxState.types"

export const AppSidebar = (): ReactElement => {
  const dispatch = useDispatch()
  const { backgroundColor, enableSidebarShadow, enableClosedSidebar, enableMobileMenu } = useSelector(
    (state: ReduxState) => state.ThemeOptions,
  )

  const toggleLeftSidebar = (enable: boolean) => {
    dispatch(setEnableClosedSidebar(enable))
  }

  const toggleMobileSidebar = () => {
    // eslint-disable-next-line no-shadow
    dispatch(setEnableMobileMenu(!enableMobileMenu))
  }

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-sidebar", backgroundColor, { "sidebar-shadow": enableSidebarShadow })}
        transitionName="SidebarAnimation"
        transitionAppear
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PerfectScrollbar style={{ position: "relative", paddingRight: 0 }}>
          <div className="app-sidebar__inner">
            <div style={{ height: 46, overflow: "hidden", marginBottom: 43 }}>
              <img src={Logo} alt="" className="logo-solo" style={{ height: "100%" }} />
            </div>

            <Nav toggleSidebar={toggleLeftSidebar} enableClosedSidebar={enableClosedSidebar} />
          </div>
        </PerfectScrollbar>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
