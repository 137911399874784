import logo from "assets/logos/pickme-logo.png"
import React from "react"
import "./styles.scss"

const Menu = () => {
  return (
    <div className="menu-container">
      <div className="logo-container">
        <img className="logo" alt="logo" src={logo}></img>
      </div>
    </div>
  )
}

export default Menu
