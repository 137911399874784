import React from "react"
import { ReactElement, useEffect, useRef, useState } from "react"
import Talk from "talkjs" // https://talkjs.com/docs/Getting_Started/Frameworks/React/

import config from "config"
import { Loader } from "modules/shared/components/Loader/Loader"
import {
  ChatContainer,
  Container,
  DeliveredParcelContainer,
  NoteContainer,
  TellUsMoreLink,
  ThankYouNoteContainer,
} from "modules/DestiChat/Chat.styled"
import { Emoji } from "modules/shared/components/Emoji"
import { ParcelEventCode, ParcelEventReason, SaveParcelEventInput } from "types/parcel.event.types"
import { ChatAccessRights, OrderChat } from "types/chat.types"
import { OrderStatus } from "types/order.types"

const DeliveredParcel = ({ order }: { order: OrderChat }): ReactElement => {
  return order.orderStatus === OrderStatus.RETURN_REQUESTED || order.orderStatus === OrderStatus.RETURNING ? (
    <DeliveredParcelContainer>
      <NoteContainer>
        <Emoji label="party popper" size="extra-big" style={{ marginBottom: "20px" }}>
          😔
        </Emoji>
        <b>Vous avez refusé votre colis</b>
        <ThankYouNoteContainer>
          {
            "Votre colis sera récupéré par le transporteur dans les prochains jours. Vous serez ensuite recontacté pour la suite de votre livraison."
          }
        </ThankYouNoteContainer>
      </NoteContainer>
      <TellUsMoreLink to={`/rating/${order.orderId}`}>{"Racontez nous comment ça s'est passé"}</TellUsMoreLink>
    </DeliveredParcelContainer>
  ) : (
    <DeliveredParcelContainer>
      <NoteContainer>
        <Emoji label="party popper" size="extra-big" style={{ marginBottom: "20px" }}>
          🎉
        </Emoji>
        <b>Votre colis a été livré !</b>
        <ThankYouNoteContainer>
          {"Merci d'avoir utilisé Pickme pour sauver votre colis"}
          <Emoji label="hug" style={{ marginLeft: "5px" }}>
            🤗
          </Emoji>
        </ThankYouNoteContainer>
      </NoteContainer>
      <TellUsMoreLink to={`/rating/${order.orderId}`}>{"Racontez nous comment ça s'est passé"}</TellUsMoreLink>
    </DeliveredParcelContainer>
  )
}

interface ChatProps {
  handleSaveParcelEvent: (payload: SaveParcelEventInput) => void
  order: OrderChat
}

export const Chat = ({ order, handleSaveParcelEvent }: ChatProps): ReactElement => {
  const parcelIsDelivered = order.chatAccessRights === ChatAccessRights.READ_ONLY

  // TalkJS
  const chatboxEl = useRef()
  const [talkLoaded, markTalkLoaded] = useState(false)
  // wait for TalkJS to load
  Talk.ready.then(() => markTalkLoaded(true))

  useEffect(() => {
    if (talkLoaded) {
      // Create Users
      const currentUser = new Talk.User(order.orderId)

      // Connect to Session
      const session = new Talk.Session({
        appId: config.talkJsAppId,
        me: currentUser,
        signature: order.chatSignature,
      })

      // Create or Get conversation
      const conversationId = order.chatId
      const conversation = session.getOrCreateConversation(conversationId)

      // Create Chatbox
      const chatbox = session.createChatbox({
        messageField: { visible: !parcelIsDelivered },
        theme: "pickme",
      })
      chatbox.select(conversation)
      chatbox.mount(chatboxEl.current)

      handleSaveParcelEvent({
        parcelId: order.parcelId,
        title: "Le Recipient se connecte au chat",
        description: "Affichage du chat sur la webPlateforme.",
        parcelEventCode: ParcelEventCode.RECIPIENT_CONNECTED_ON_CHAT,
        parcelEventReason: ParcelEventReason.SCREEN_VIEW,
      })

      return () => session.destroy()
    }
  }, [talkLoaded])

  return (
    <Container>
      <ChatContainer ref={chatboxEl}>
        <div>
          <Loader />
        </div>
      </ChatContainer>

      {parcelIsDelivered && <DeliveredParcel order={order} />}
    </Container>
  )
}
