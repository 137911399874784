import styled from "styled-components"
import { silverGrey } from "utils/color"

interface GeneralProps {
  isKeeperRate: boolean
}

interface StarItemProps extends GeneralProps {
  isHovered: boolean
}

export const StarRatingContainer = styled.div``

export const StarContainer = styled.div<GeneralProps>`
  display: grid;
  margin: 0 auto;
  width: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "100%" : "fit-content")};
  row-gap: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "9px" : "20px")};
  column-gap: 0;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;

  @media (max-width: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "650px" : "560px")}) {
    grid-template-columns: ${({ isKeeperRate }: GeneralProps) =>
      isKeeperRate ? "repeat(2, 140px)" : "repeat(2, 100px)"};
    column-gap: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "40px" : "20px")};
    row-gap: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "25px" : "20px")};
  }
`

export const StarItem = styled.div<GeneralProps>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  transition: all ease 0.3s;
  margin: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "0 auto" : "0 8px 0 8px")};

  @media (max-width: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "450px" : "350px")}) {
    margin: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "0 auto" : "0 8px 0 8px")};
  }
`

export const Star = styled.span<StarItemProps>`
  margin-bottom: ${({ isKeeperRate }: StarItemProps) => (isKeeperRate ? "42px" : "0")};
  cursor: pointer;
  transition: all ease 0.3s;
  scale: ${({ isHovered, isKeeperRate }: StarItemProps) => (isKeeperRate ? 1.1 : isHovered ? 1.2 : 1)};

  @media (max-width: ${({ isKeeperRate }: StarItemProps) => (isKeeperRate ? "650px" : "350px")}) {
    scale: ${({ isHovered, isKeeperRate }: StarItemProps) => (isKeeperRate ? 1.2 : isHovered ? 1.2 : 1)};
    margin-bottom: ${({ isKeeperRate }: StarItemProps) => (isKeeperRate ? "25px" : "0")};
  }

  @media (max-width: 350px) {
    scale: ${({ isHovered, isKeeperRate }: StarItemProps) => (isKeeperRate ? 1 : isHovered ? 1.2 : 1)};
  }
`

export const StarTextItem = styled.div<GeneralProps>`
  display: flex;
  height: 35px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${silverGrey};
  font-size: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "13px" : "10px")};
  margin: ${({ isKeeperRate }: GeneralProps) => (isKeeperRate ? "0 6px 0 6px" : "0 8px 0 8px")};
`

export const Text = styled.p`
  margin: 0;
`
