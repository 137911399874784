import React, { ReactElement } from "react"
import { Title } from "modules/Carriers/components/Title/Title.styled"

interface PageTitleProps {
  children: ReactElement | string
}

const PageTitle = ({ children }: PageTitleProps): ReactElement => {
  return <Title>{children}</Title>
}

export default PageTitle
