import { ButtonWithIcon } from "modules/shared/components/Button/Button"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { corail, grey, white } from "utils/color"
import PeerChallengeFeedbackContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import "modules/rating/screens/styles.scss"
import { AnimateProperties, AnswerInput, RatingState } from "types/rating.types"
import { isUndefined } from "utils/isUndefined"
import SatisfactionLevel from "modules/rating/components/Survey/SatisfactionLevel/SatisfactionLevel"
import DeliveryMethod from "modules/rating/components/Survey/DeliveryMethod/DeliveryMethod"
import HeardAboutPickme from "modules/rating/components/Survey/HeardAboutPickme/HeardAboutPickme"
import ChooseAgain from "modules/rating/components/Survey/ChooseAgain/ChooseAgain"
import { initialSurveyRatingState } from "modules/rating/datas/Survey/SurveyDatas"
import {
  ActionContainer,
  ActionContainerSurveySpacing,
  CtaBlocBottom,
  CtaContainer,
  PeerRating,
  QuestionBlock,
  StepContainer,
} from "modules/rating/screens/Rating.styled"
import { backgroundGrey } from "theme/colors"

interface SurveyProps {
  onSurveyUpdated: (answers: AnswerInput[]) => void
}

export const Survey = ({ onSurveyUpdated }: SurveyProps): ReactElement => {
  const [ratingState, setRatingState] = useState<RatingState>(initialSurveyRatingState)

  /**
   * A ref to track whether data has been transferred to the server.
   * Initialized as `false`, it will be set to `true` once data is transferred to prevent duplicate transfers.
   */
  const isDataSent = useRef(false)

  /** Variable that stores answered questions only */
  const filteredAnswersRef = useRef<AnswerInput[]>([])

  function getButtonBackgroundColor(selected: boolean): string {
    return selected ? corail : backgroundGrey
  }

  function handleRatingUpdate(question: AnswerInput, item: string | number) {
    setRatingState(prevState => ({
      ...prevState,
      [`q${question.order}`]: { ...question, note: item },
    }))
  }

  function getAnimateProperties(question: AnswerInput, item: number): AnimateProperties {
    return {
      background: getButtonBackgroundColor(question.note === item),
      color: question.note === item ? white : grey,
    }
  }

  const isDisabled =
    isUndefined(ratingState.q6.note) ||
    isUndefined(ratingState.q7.note) ||
    isUndefined(ratingState.q8.note) ||
    isUndefined(ratingState.q9.note) ||
    isUndefined(ratingState.q10.note) ||
    isUndefined(ratingState.q11.note) ||
    isUndefined(ratingState.q12.note) ||
    isUndefined(ratingState.q13.note) ||
    isUndefined(ratingState.q14.note) ||
    isUndefined(ratingState.q15.note) ||
    isUndefined(ratingState.q16.note) ||
    isUndefined(ratingState.q17.note) ||
    isUndefined(ratingState.q18.note)

  filteredAnswersRef.current = Object.values(ratingState).filter(question => !isUndefined(question.note))

  /**
   * This useEffect cleanup function handles the scenario where the user leaves the form
   * before completing it. It's responsible for sending the collected data to the server
   * when the component unmounts. If the user hasn't sent the data (tracked by isDataSent),
   * this function triggers the data transfer.
   */
  useEffect(() => {
    return () => {
      if (!isDataSent.current && filteredAnswersRef.current.length) {
        onSurveyUpdated(filteredAnswersRef.current)
      }
    }
  }, [])

  return (
    <PeerChallengeFeedbackContainer>
      <StepContainer>
        <ActionContainer wider={true}>
          <ActionContainerSurveySpacing>
            <PeerRating>
              <QuestionBlock>
                <SatisfactionLevel
                  ratingState={ratingState}
                  handleRatingUpdate={handleRatingUpdate}
                  getAnimateProperties={getAnimateProperties}
                />
                <DeliveryMethod
                  ratingState={ratingState}
                  handleRatingUpdate={handleRatingUpdate}
                  getAnimateProperties={getAnimateProperties}
                />
                <HeardAboutPickme
                  ratingState={ratingState}
                  handleRatingUpdate={handleRatingUpdate}
                  getAnimateProperties={getAnimateProperties}
                />
                <ChooseAgain
                  ratingState={ratingState}
                  handleRatingUpdate={handleRatingUpdate}
                  getAnimateProperties={getAnimateProperties}
                />
              </QuestionBlock>
            </PeerRating>
          </ActionContainerSurveySpacing>

          <CtaBlocBottom>
            <CtaContainer>
              <ButtonWithIcon
                text="Suivant"
                disabled={isDisabled}
                icon={null}
                onClick={() => {
                  onSurveyUpdated(filteredAnswersRef.current)
                  isDataSent.current = true
                }}
              />
            </CtaContainer>
          </CtaBlocBottom>
        </ActionContainer>
      </StepContainer>
    </PeerChallengeFeedbackContainer>
  )
}
