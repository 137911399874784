export enum RatingVersions {
  STANDARD_V1 = "STANDARD_V1",
  STANDARD_V2 = "STANDARD_V2",
  STANDARD_V3 = "STANDARD_V3",
  STANDARD_V4 = "STANDARD_V4",
  COLISSIMO_V1 = "COLISSIMO_V1",
  COLISSIMO_V2 = "COLISSIMO_V2",
  COLISSIMO_V4 = "COLISSIMO_V4",
}

export enum AnswerType {
  OUT_OF_10 = "OUT_OF_10",
  OUT_OF_5 = "OUT_OF_5",
  CHOICE = "CHOICE",
  AGREE_DISAGREE = "AGREE_DISAGREE",
  FREE_TEXT = "FREE_TEXT",
}

export interface AnswerInput {
  order: number
  type: AnswerType
  note: string | number
  tips?: number
}

export type RatingState = Record<string, AnswerInput>

export interface FormProps {
  ratingState: RatingState
  handleRatingUpdate: (question: AnswerInput, item: string | number) => void
  getAnimateProperties: (question: AnswerInput, item: number) => AnimateProperties
  rate?: number
}

export interface AnimateProperties {
  background: string
  color: string
}

export interface TipsInput {
  orderId: string
  amount: number
}
