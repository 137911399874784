import { gql } from "apollo-boost"
import { TipsInput } from "types/rating.types"

export interface PrepareTipsPaymentLinkData {
  prepareTipsPaymentLink: {
    id: string
    url: string
  }
}

export interface PrepareTipsPaymentLinkVariables {
  tipsInput: TipsInput
}

export const prepareTipsPaymentLink = gql`
  mutation prepareTipsPaymentLink($tipsInput: TipsInput!) {
    prepareTipsPaymentLink(tipsInput: $tipsInput) {
      id
      url
    }
  }
`
