import { useMutation } from "@apollo/client"
import { SAVE_PARCEL_EVENT } from "services/graphql/mutations/saveParcelEvent.mutations"
import { SaveParcelEventInput } from "types/parcel.event.types"

export const useSaveParcelEventMutation = () => {
  const [saveParcelEventMutation] = useMutation(SAVE_PARCEL_EVENT)

  const saveParcelEvent = (payload: SaveParcelEventInput) => {
    saveParcelEventMutation({
      variables: {
        payload,
      },
    })
  }

  return [saveParcelEvent]
}
