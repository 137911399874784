import styled from "styled-components"
import { green, grey } from "utils/color"
import { motion } from "framer-motion"
import { boxShadow1 } from "theme/boxShadows"
import { inputColor, lightGrey } from "theme/colors"

export const StyledInput = styled(motion.div)`
  position: relative;
  border-radius: 8px;
  transition: all 0.5s ease-out;
  height: 40px;

  &[data-behavior="textarea"] {
    height: 70px;
  }

  input,
  textarea {
    margin: 0;
    border: 0;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    line-height: 1;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    font-size: 0.9em;
    color: ${grey}; /* Replace with your color variable */
    font-family: inherit;
    border: 0.4px solid ${grey}; /* Replace with your color variable */
    background-color: ${inputColor};

    &:focus {
      outline: 0;
      box-shadow: ${boxShadow1}; /* Replace with your box-shadow variable */
      border: 0.5px solid darken(${inputColor}, 10%);
    }
  }
`

export const InputTextarea = styled(StyledInput)`
  font-size: 1.1em;
  border-radius: 0;

  &[data-behavior="textarea"] {
    height: 120px;
  }

  textarea {
    resize: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
    border-bottom: solid 0.5px ${green};
    padding: 1.2em;

    &:focus {
      border-bottom: solid 2px ${green};
    }
  }
`

export const Limit = styled(motion.div)`
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 0.7em;
  color: ${lightGrey};
`

export const MaxLimit = styled(motion.div)`
  font-family: Poppins;
  font-style: italic;
  position: absolute;
  bottom: 10px;
  right: 24px;
  z-index: 90;
  color: ${lightGrey};
  font-size: 0.7em;
`

export const Help = styled(motion.div)`
  position: absolute;
  right: 10px;
  top: 10px;
  padding-left: 10px;
  color: ${green}; /* Replace with your color variable */
  background: linear-gradient(90deg, rgba(${inputColor}, 0.5) 5%, ${inputColor} 100%);
`
