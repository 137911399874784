import React from "react"
import { BoldText } from "modules/rating/screens/Rating.styled"
import bottomRight from "assets/banner-images/bottomRight.png"
import topLeft from "assets/banner-images/topLeft.png"
import {
  Banner,
  BannerHeader,
  BannerText,
  BannerWrapper,
  ImageBottomRight,
  ImageTopLeft,
} from "modules/shared/components/Banner/Banner.styled"

const RateBanner = () => {
  return (
    <BannerWrapper>
      <Banner>
        <ImageBottomRight src={bottomRight} alt={"bottom right image"} />
        <ImageTopLeft src={topLeft} alt={"top left image"} />
        <BannerHeader>
          <BoldText>Bravo, vous avez choisi une livraison plus solidaire et plus responsable ! 📦</BoldText>
        </BannerHeader>
        <BannerText>
          Poursuivez votre impact positif en retrouvant la livraison Pickme sur les plus grands sites e-commerce comme{" "}
          <BoldText>
            Veepee, Showroomprive.com, Leroy Merlin, Nespresso, Kiabi, Yves Rocher, Lacoste, Okaidi, Adidas, Levis,
            Carrefour, Pandora, Vestiaire Collective
          </BoldText>
          , et bien d&apos;autres.
        </BannerText>
      </Banner>
    </BannerWrapper>
  )
}

export default RateBanner
