import { CarriersName } from "types/carriers.types"

export enum ChatAccessRights {
  NO_ACCESS = "NO_ACCESS",
  WRITE = "WRITE",
  READ_ONLY = "READ_ONLY",
}

export interface OrderChat {
  shipperName: string | null
  carrierName: CarriersName | null
  parcelId: string | null
  parcelNo: string | null
  orderId: string | null
  orderStatus: string | null
  chatId: string | null
  chatAccessRights: ChatAccessRights
  keeperPictureUrl: string | null
  keeperFirstName: string | null
  chatSignature: string | null
}

export interface OrderChatData {
  getOrderChatData: OrderChat
}
