import checkAnimationData from "assets/lottie-animations/check.json"
import { motion } from "framer-motion"
import { OperationStates } from "models/operation-states.model"
import { Order } from "models/order.model"
import { bottomToTopItem, topToBottom } from "modules/shared/animations/variants"
import React from "react"
import Lottie from "react-lottie"
import PeerChallengeFeedbackContainer from "../../components/KeeperRatingContainer/KeeperRatingContainer"

const CheckAnimationOptions = {
  loop: true,
  autoplay: true,

  animationData: checkAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const FeedbackSubmission: React.FC<{
  order: Order
  submissionState: OperationStates
}> = ({ order, submissionState }) => {
  return (
    <PeerChallengeFeedbackContainer>
      <div className="step-container">
        <div className="action-container validation">
          {submissionState === OperationStates.InProgress && (
            <div className="message">
              <Lottie key="validation" options={{ ...CheckAnimationOptions, loop: true }} height={100} width={100} />
              <motion.div variants={topToBottom} className="title">
                Soumission en cours
              </motion.div>
            </div>
          )}
          {submissionState !== OperationStates.InProgress && (
            <>
              <div className="title">
                <b>Un grand MERCI 🙌</b>
              </div>
              <br />

              <p>
                Merci d&apos;avoir pris le temps de répondre, votre avis est très précieux pour <br /> nous !
              </p>

              <p>Cela va nous aider à améliorer notre service 🙏</p>
              <br />
              <p>À très vite pour de nouvelles livraisons avec Pickme et {order?.deliveryCarrierName} 📦</p>

              <motion.div className="discover-pickme" variants={bottomToTopItem}>
                <a href="https://www.mypickme.com">
                  <b>Découvrir Pickme, la livraison responsable 🌱</b>
                </a>
              </motion.div>
            </>
          )}
        </div>
      </div>
    </PeerChallengeFeedbackContainer>
  )
}

export default FeedbackSubmission
