import React, { ReactNode } from "react"
import Radium from "radium"
import { Loader } from "semantic-ui-react"
import { ButtonContainer, Wrapper } from "modules/Carriers/components/Buttons/ConnectButton/ConnectButton.styled"

interface ConnectButtonProps {
  text: string
  onClick?: () => void
  children: ReactNode
  isSubmitting?: boolean
}

export const ConnectButton = Radium(({ text, onClick, children, isSubmitting }: ConnectButtonProps) => {
  return (
    <Wrapper>
      <ButtonContainer
        type="submit"
        onClick={e => {
          if (onClick !== undefined) {
            e.preventDefault()
            onClick()
          }
        }}
      >
        {isSubmitting !== undefined && isSubmitting === true ? <Loader inline active size="tiny" /> : text} {children}
      </ButtonContainer>
    </Wrapper>
  )
})
