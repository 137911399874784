export const devices = {
  mobile: `(max-width: 620px)`,
}

export const fontSizes = {
  mobile: {
    small: "11px",
    regular: "12px",
    medium: "14px",
  },
  desktop: {
    small: "12px",
    regular: "14px",
    medium: "16px",
    extraBig: "26px",
  },
}
