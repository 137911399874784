import Routes from "modules/Routes"
import React from "react"
import "styles/App.scss"
import "styles/base.css"
import "styles/custom.css"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "services/graphql/apollo-client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import configureStore from "config/configureStore"

const App: React.FC = () => {
  const { store, persistor } = configureStore()
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}

export default App
