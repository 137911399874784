export const grey = "#344356"
export const lightCorail = "#fef7f7"
export const corail = "#f76c73"
export const yellow = "#fed330"
export const oldGreen = "#26de81" // @Florian ? can we replace him by `green` ?
export const green = "#33CC99"
export const mediumGreen = "#55BA86"
export const white = "#ffffff"
export const black = "#000000"
export const silverGrey = "#BEBEBE"
export const ghostWhite = "#f8f9fc"
export const nobelGrey = "#a1a1a1"
export const red = "#dc0000"
export const mediumLightGrey = "#9a9a9a"
export const boxShadowColor = "#cfcdcd40"
export const darkGrey = "#424242"
