import styled from "styled-components"

import { white } from "utils/color"

export const WhiteCard = styled.div`
  background-color: ${white};
  border-radius: 30px;
  box-shadow: 3px 0px 20px 5px rgba(197, 197, 197, 0.25);
  padding: 25px;
`
