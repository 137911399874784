import { motion } from "framer-motion"
import React, { ReactElement, ReactNode } from "react"
import "modules/rating/components/KeeperRatingContainer/styles.scss"

interface KeeperRatingContainerProps {
  children: ReactNode
}

const KeeperRatingContainer = ({ children }: KeeperRatingContainerProps): ReactElement => {
  return (
    <>
      <motion.div className="keeper-rating-container">{children}</motion.div>
    </>
  )
}

export default KeeperRatingContainer
