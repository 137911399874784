import styled from "styled-components"
import { Row } from "reactstrap"

export const LineElementRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: stretch;
  }
`
