import styled from "styled-components"
import { red } from "utils/color"
import { davyGrey } from "theme/colors"

export const Container = styled.div`
  font-size: 0.8em;
  margin-bottom: 3px;
`

export const InputLabel = styled.div`
  color: ${davyGrey};
  font-size: 10px;
`

export const CrossRequired = styled.span`
  color: ${red};
  font-size: 1em;
  position: relative;
  bottom: 1px;
  left: 3px;
`

export const InputError = styled.div`
  margin-bottom: 10px;
  color: ${red}
  font-size: 0.7em;
`
