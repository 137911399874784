export enum OrderStatus {
  CREATED = "CREATED",
  IN_PREPARATION = "IN_PREPARATION",
  READY_TO_SHIP = "READY_TO_SHIP",
  IN_TRANSIT = "IN_TRANSIT",
  IN_DELIVERY = "IN_DELIVERY",
  /** BTOC STATUSES */
  KEEPER_DELIVERY_FAILURE = "KEEPER_DELIVERY_FAILURE",
  DELIVERED_TO_KEEPER = "DELIVERED_TO_KEEPER",
  RECIPIENT_DELIVERY_FAILURE = "RECIPIENT_DELIVERY_FAILURE",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  RETURN_REQUESTED = "RETURN_REQUESTED",
  RETURNING = "RETURNING",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  /** CTOB STATUSES */
  WAITING_FOR_DROPOFF = "WAITING_FOR_DROPOFF",
  WAITING_FOR_NEW_KEEPER_CHOICE = "WAITING_FOR_NEW_KEEPER_CHOICE",
  KEEPER_DROPOFF_FAILURE = "KEEPER_DROPOFF_FAILURE",
  DROPPED_TO_KEEPER = "DROPPED_TO_KEEPER",
  KEEPER_CARRIER_DELIVERY_FAILURE = "KEEPER_CARRIER_DELIVERY_FAILURE",
  DROPOFF_RETURN_IN_PROGRESS = "DROPOFF_RETURN_IN_PROGRESS",
  SHIPPER_DELIVERY_FAILURE = "SHIPPER_DELIVERY_FAILURE",
  DELIVERED_TO_SHIPPER = "DELIVERED_TO_SHIPPER",
  /** OTHERS */
  LOST = "LOST",
  CANCELLED = "CANCELLED",
}

export enum ManualOrderPacmanAuthorizedStatus {
  IN_TRANSIT = "IN_TRANSIT",
  DELIVERED_TO_KEEPER = "DELIVERED_TO_KEEPER",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  RETURN_TO_PLAN = "RETURN_TO_PLAN",
}

export interface UnannouncedParcelState {
  id: string
  parcelNo: string
  orderId: string
  firstName: string
  lastName: string
  phone: string
  email: string
  createdAt: Date
}
