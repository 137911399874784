import { motion } from "framer-motion"
import React from "react"
import Popup from "reactjs-popup"
import "./styles.scss"

const HelpModal: React.FC<{
  visible: boolean
  setVisible: (visible: boolean) => void
}> = ({ visible, setVisible }) => {
  return (
    <Popup className="feedback-help-popup" open={visible} closeOnDocumentClick onClose={() => setVisible(false)}>
      <motion.div className="modal">
        <div className="title">Aide</div>
        <div className="help-modal-content">
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetu.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
          </p>
        </div>
        <div className="modal-close">
          <span className="link-like" onClick={() => setVisible(false)}>
            Fermer
          </span>
        </div>
      </motion.div>
    </Popup>
  )
}

export default HelpModal
