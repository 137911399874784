import styled from "styled-components"
import { silverGrey } from "utils/color"

export const DoubleArrow = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 20px;
  left: 1em;
  color: ${silverGrey};
  cursor: pointer;
`
