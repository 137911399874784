import emptyAnimationData from "assets/lottie-animations/empty.json"
import KeeperRatingContainer from "modules/rating/components/KeeperRatingContainer/KeeperRatingContainer"
import React from "react"
import Lottie from "react-lottie"

const EmptyAnimationsOptions = {
  loop: true,
  autoplay: true,

  animationData: emptyAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const Error: React.FC = () => {
  return (
    <KeeperRatingContainer>
      <div className="step-container">
        <div className="action-container">
          <div className="message">
            <div className="message">
              <Lottie
                key="validation"
                options={{ ...EmptyAnimationsOptions, loop: true }}
                height={200}
                width={200}
                style={{ marginBottom: 20 }}
              />
              <div className="title">
                Oups{" "}
                <span role="img" aria-label="confused">
                  😓
                </span>
              </div>
              <div className="content">{`Nous n'avons pas réussi à charger la page demandée`}</div>
            </div>
          </div>
        </div>
      </div>
    </KeeperRatingContainer>
  )
}

export default Error
