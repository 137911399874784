import React, { ReactElement } from "react"
import { silverGrey, yellow } from "utils/color"

interface SmallStarProps {
  index: number
  rating: number | undefined
  hoverRating: number | undefined
}

export const LargeStar = ({ index, rating, hoverRating }: SmallStarProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="104"
      viewBox="0 0 106 104"
      fill={index < rating || index < hoverRating ? yellow : silverGrey}
    >
      <path
        d="M51.7956 1.05293C52.088 0.117449 53.412 0.117444 53.7044 1.05292L65.3841 38.4119C65.5146 38.8293 65.9012 39.1135 66.3385 39.1135H104.214C105.174 39.1135 105.582 40.3334 104.817 40.9115L74.1092 64.0987C73.7722 64.3532 73.6314 64.792 73.7574 65.1951L85.4708 102.662C85.761 103.59 84.6899 104.345 83.9138 103.759L53.3526 80.682C52.996 80.4127 52.504 80.4127 52.1474 80.682L21.5862 103.759C20.8102 104.345 19.739 103.59 20.0292 102.662L31.7426 65.1951C31.8686 64.792 31.7278 64.3532 31.3908 64.0987L0.683224 40.9115C-0.0823908 40.3334 0.326458 39.1135 1.28582 39.1135H39.1615C39.5988 39.1135 39.9854 38.8293 40.1159 38.4119L51.7956 1.05293Z"
        fill={index < rating || index < hoverRating ? yellow : "none"}
        stroke={index < rating || index < hoverRating ? "none" : silverGrey}
      />
    </svg>
  )
}
