import React, { ReactElement } from "react"
import { Form as SemForm } from "semantic-ui-react"
import {
  Container,
  CrossRequired,
  InputError,
  InputLabel,
} from "modules/Carriers/components/ClassicInput/ClassicInput.styled"

interface ClassicInputProps {
  field: { name: string }
  label?: string
  required?: boolean
  placeholder: string
  form: { touched: string[]; errors: string[] }
}

export const ClassicInput = ({
  field,
  label = null,
  required = false,
  placeholder,
  form: { touched, errors },
  ...props
}: ClassicInputProps): ReactElement => {
  return (
    <Container>
      <InputLabel>
        {label !== null && label}
        {label !== null && required && <CrossRequired>*</CrossRequired>}
      </InputLabel>

      <SemForm.Input
        {...field}
        {...props}
        fluid
        placeholder={placeholder}
        style={{ marginTop: 1, height: 32, fontSize: 12 }}
        error={touched[field.name] && errors[field.name]}
        required={required}
      />

      {touched[field.name] && required && errors[field.name] && <InputError>{errors[field.name]}</InputError>}
    </Container>
  )
}
