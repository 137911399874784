import React from "react"

import { ThreeDots } from "react-loader-spinner"
import { corail } from "utils/color"

const loaderWrapperStyle = {
  display: "flex",
  justifyContent: "center",
}

const Loader: React.FC = () => (
  <ThreeDots
    height="80"
    width="80"
    radius="9"
    color={corail}
    ariaLabel="three-dots-loading"
    wrapperStyle={loaderWrapperStyle}
    wrapperClass=""
    visible={true}
  />
)

export { Loader }
